<script>
    import {
        Button,
        TextField,
        List,
        Subheader,
        ListItemGroup,
        ListItem,
        Checkbox,
        Avatar,
        MaterialApp,
        Row,
        Icon,
    } from "svelte-materialify";
    import Modal, { getModal } from "../utils/Modal.svelte";

    export let list;
    let product_string = "";
    let checked = [];
    let json = null;
    let y = 0;
    // export let id=1;
    async function search_product(product_string) {
        json = null;
        checked = [];
        if (product_string.length < 3) {
            json = null;
            checked = [];
        }
        if (product_string.length > 2) {
            json = null;
            checked = [];
            try {
                const res = await fetch(
                    `/api/v1/shop/search/product/` + product_string
                    // {
                    //     method: "GET",
                    //     credentials: "include",
                    //     headers: {
                    //         Authorization:
                    //             "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    //             "Content-Type": "application/json"
                    //     }
                    // }
                );
                json = await res.json();
                if (res.ok) {
                    for (let i = 0; i < list.length; i++) {
                        y = json.findIndex(
                            (arr) => arr.id_product == list[i].id_product
                        );
                        if (y != -1) {
                            checked.push(y);
                        }
                    }
                } else {
                    checked = [];
                    json = null;
                }
            } catch (error) {
                checked = [];
                json = null;
            }
        }
    }

    let product_check = 0;

    function addProduct(item) {
        product_check = list.findIndex(
            (arr) => arr.id_product == item.id_product
        );

        if (product_check != -1) {
            list.splice(product_check, 1);
            // notifier.info("Η κατ: " + cat.category_name + " αφαιρέθηκε.", 4000);
            // checked.push(json.findIndex(arr => arr.id == cat.id));
        } else {
            item.id = item.id_product;
            list.push(item);
        }
        list = list;
        // getModal('search_product').close(list);
    }
</script>

<div class="">
    <MaterialApp>
        <!-- opening a model with an `id` and specifying a callback	 -->
        <Row style="margin-left: 4px;margin-right: 4px;">
            <TextField
                filled
                bind:value={product_string}
                on:input={search_product(product_string)}
            >
                <div slot="append" on:click={()=>{json=null; product_string=''}}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
                        />
                    </svg>
                </div>
                 Όνομα προιόντος</TextField
            >
            <!-- <Button
            fab
            size="default"
            class="blue white-text"
            on:click={reorder(list)}>Add</Button
        > -->
        </Row>
        <div class="d-flex justify-center max-h-[500px] overflow-y-auto">
            <List class="" style="width:500px">
                {#if json != null}
                    <ListItemGroup multiple bind:value={checked}>
                        {#each json as item, i}
                            <ListItem on:click={addProduct(item)}>
                                <span slot="prepend">
                                    <Checkbox checked={checked.includes(i)} />
                                </span>
                                {item.name_product}
                                <span slot="subtitle">
                                    Id: {item.code} Brand: {item.product_brand}
                                </span>
                                <span slot="append">
                                    <Avatar
                                        ><img
                                            src="https://medil.gr/site_images/{item.image_product}"
                                            alt="a"
                                        /></Avatar
                                    >
                                </span>
                            </ListItem>
                        {/each}
                    </ListItemGroup>
                {/if}
            </List>
        </div>
    </MaterialApp>
</div>
