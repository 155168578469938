<script>
    import {ProgressCircular} from "svelte-materialify";
</script>
<div class="d-flex justify-center align-center">
<ProgressCircular
    size={70}
    width={7}
    indeterminate
    color="success"
/>
</div>