<script>
    import {
        Tabs,
        Tab,
        MaterialApp,
        TabContent,
        Badge,
    } from "svelte-materialify";
    import Info from "./GalaxyProductInfo.svelte";
    import Prices from "./GalaxyPriceInfo.svelte";
    import Edit from "./ProductEdit.svelte";
    import OthersProducts from "./OthersProducts.svelte";
    import UploadTab from "./UploadTab.svelte";

    export let gxidTab = null;
    export let idbTab = null;
    export let edit = false;
    export let product = null;
    let counterOthers = 0,
        counterFiles = 0;

    if(idbTab!=null){
        if (product.other_products != null) {
            if (product.other_products.items.length) {
                counterOthers = product.other_products.items.length;
            }
        }
        if (product.files != null) {
            if (product.files.items.length) {
                counterFiles = product.files.items.length;
            }
        }
    }
    // $: console.log("pro cta",product);
    // $: console.log("len", product.files.items.length);

</script>

<MaterialApp>
    <Tabs grow class="green-text">
        <div slot="tabs">
            <Tab>Επεξεργασια προιοντος</Tab>

            <Tab
                ><Badge class="primary-color" value={counterOthers}
                    >Επιλογές προιοντος
                </Badge>
            </Tab>

            <Tab
                ><Badge class="success-color" value={counterFiles}
                    >αρχεια προιοντος
                </Badge>
            </Tab>

            <Tab>Πληροφοριες προιοντος</Tab>
            <Tab>Οικονομικα προιοντος</Tab>
        </div>
        <TabContent>
            {#if edit}
                <Edit idb={idbTab} />
            {:else}
                <Edit gxid={gxidTab} />
            {/if}
        </TabContent>
        <TabContent disabled={() => (edit ? false : true)}>
            {#if edit}
                <OthersProducts gxid={gxidTab} idb={idbTab} {product} />
            {/if}
        </TabContent>
        <TabContent disabled={() => (edit ? false : true)}>
            {#if edit}
                <UploadTab gxid={gxidTab} idb={idbTab} {product} />
            {/if}
        </TabContent>
        <TabContent>
            <Info id={gxidTab} />
        </TabContent>
        <TabContent>
            <Prices />
        </TabContent>
    </Tabs>
</MaterialApp>
