<script>
    import {
        Dialog,
        Snackbar,
        Overlay,
        ProgressCircular,
        Select,MaterialApp
    } from "svelte-materialify";
    import Upload from "../utils/Upload.svelte";
    import { FileDropzone } from "attractions";
    import { flip } from "svelte/animate";
    import { dndzone } from "svelte-dnd-action";

    let timokatalogos;
    let dialogShowAdd = false;
    let snackbar = false;
    let imageCatalog;
    let imageExist = null;
    let pdf = [];
    let catalogName = "";
    let dragDisabled = true;
    const dropTargetStyle = {};
    let editId = false;
    // $:console.log(pdf[0])
    let items;
    const flipDurationMs = 700;
    let reqPdf = true;
    let active = false;
    let timokEidikotites=[];

    async function getEidikotites() {
        const res = await fetch("/api/v1/settings/fr/2");
        const resj = await res.json();
        return resj;
    }

    function handleDndConsider(e) {
        items = e.detail.items;
        timokatalogos.data = items;
    }
    function handleDndFinalize(e) {
        items = e.detail.items;
        timokatalogos.data = items;
    }
    async function fetchTimokatalogoi() {
        const res = await fetch(`/api/v1/settings/catalog`);
        if (res.ok) {
            timokatalogos = await res.json();
            // console.log(timokatalogos);
            if (timokatalogos.data.length) {
                items = timokatalogos.data;
            }
            return timokatalogos;
        } else {
            timokatalogos = false;
            // console.log(timokatalogos);
            return timokatalogos;
        }
    }
    async function addTimokatalogos() {
        active=true;
        const uploadImage = await imageCatalog.uploads_img();
        try {
            let position = null;
            let path;
            if (editId) {
                for (let i = 0; i < timokatalogos.data.length; i++) {
                    if (timokatalogos.data[i].id == editId) {
                        position = i;
                    }
                }
            }

            if (editId) {
                if (timokatalogos.data[position].path != pdf[0].name) {
                    const formData = new FormData();
                    formData.append("myFile", pdf[0]);
                    const post_file = await fetch(
                        "/api/v1/settings/fileupload/catalog",
                        {
                            method: "POST",
                            credentials: "include",
                            body: formData,
                        }
                    );
                    path = await post_file.json();
                } else {
                    path = {
                        path: timokatalogos.data[position].path,
                        status: true,
                    };
                }
            } else {
                const formData = new FormData();
                formData.append("myFile", pdf[0]);
                const post_file = await fetch(
                    "/api/v1/settings/fileupload/catalog",
                    {
                        method: "POST",
                        credentials: "include",
                        body: formData,
                    }
                );
                path = await post_file.json();
            }

            if (!path.status) {
                throw new Error();
            }

            if (!editId) {
                timokatalogos.data.push({
                    id: Math.floor(Math.random() * 10000),
                    image: uploadImage,
                    path: path.path,
                    name: catalogName,
                    cats: timokEidikotites
                });
            } else {
                timokatalogos.data[position] = {
                    id: editId,
                    image: uploadImage,
                    path: path.path,
                    name: catalogName,
                    cats: timokEidikotites
                };
            }

            items = timokatalogos.data;
            await saveToDb();
            timokatalogos = timokatalogos;
            dialogShowAdd = false;
            catalogName = null;
            pdf = [];
            editId = false;
            reqPdf = true;
            active=false;
            timokEidikotites=[]
        } catch (e) {
            dialogShowAdd = false;
            catalogName = null;
            pdf = [];
            editId = false;
            snackbar = true;
            console.log(e);
            reqPdf = true;
            active=false;
            timokEidikotites=[]
        }
    }

    async function saveToDb() {
        const save = await fetch("/api/v1/settings/bu/1", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: timokatalogos.id,
                data: timokatalogos.data,
                auth: timokatalogos.auth,
                name: timokatalogos.name,
            }),
        });
    }

    function deleteCatalog(id) {
        for (let i = 0; i < timokatalogos.data.length; i++) {
            if (timokatalogos.data[i].id == id) {
                timokatalogos.data.splice(i, 1);
                items = timokatalogos.data;
                saveToDb();
                timokatalogos = timokatalogos;
            }
        }
    }

    function closeModal() {
        dialogShowAdd = false;
        catalogName = null;
        pdf = [];
        editId = false;
        reqPdf = true;
    }
</script>

<Dialog class="white p-4" bind:active={dialogShowAdd} persistent>
    <Overlay absolute {active}>
        <ProgressCircular color="white" indeterminate size={128} />
    </Overlay>
    <div class="text-center text-xl">Προσθήκη τιμοκαταλόγου</div>
    <form on:submit|preventDefault={addTimokatalogos}>

        <div class="my-6 px-4">
            <label
                for="default-input"
                class="block mb-2 text-sm font-medium text-gray-900"
                >Περιγραφή καταλόγου</label
            >
            <input
                bind:value={catalogName}
                required
                type="text"
                id="default-input"
                class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
        </div>

        <div class="my-6 px-4">
            {#await getEidikotites() then eidikotites}
            <MaterialApp>
                <Select multiple outlined items={eidikotites} bind:value={timokEidikotites} class="">Ειδικότητες</Select>
            </MaterialApp>
            {/await}
        </div>

        <div class="h-36">
            <Upload
                row="true"
                {imageExist}
                bind:this={imageCatalog}
                multipleImage="false"
                name="Εικόνα καταλόγου"
                type="catalogs"
                required={true}
            />
        </div>
        <div class="h-36 my-2 px-4">
            <FileDropzone
                accept=".pdf"
                max={1}
                bind:files={pdf}
                required={reqPdf}
            />
        </div>
        <div class="flex justify-around">
            <button
                type="submit"
                class="bg-blue-500 text-white p-3 rounded-xl outline-none ring-offset-2 ring-2 ring-blue-300 hover:bg-blue-600 active:bg-blue-700"
            >
                Αποθήκευση
            </button>
            <button
                type="button"
                class="bg-gray-600 text-white p-3 rounded-xl outline-none ring-offset-2 ring-2 ring-gray-300 hover:bg-gray-700 active:bg-gray-800"
                on:click={closeModal}
            >
                Cancel
            </button>
        </div>
    </form>
</Dialog>

<div class="text-xl text-blue-500">Τιμοκατάλογοι αρχική σελίδα</div>

{#await fetchTimokatalogoi() then item}
    {#if item}
        <div class="text-xl text-green-800 my-6 flex items-center ">
            Περιγραφή
            <input
                id="name"
                class="bg-blue-100 rounded-md mx-1 px-1"
                bind:value={timokatalogos.name}
            />
            <label for="name">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path
                        fill="currentColor"
                        d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                    />
                </svg>
            </label>
            <button
                on:click={() => {
                    dialogShowAdd = true;
                    imageExist = null;
                    catalogName = "";
                    pdf = [];
                }}
                class="bg-green-600 rounded-md text-white text-xl p-1 mx-2 hover:bg-green-700 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                >+ Προσθήκη καταλόγου</button
            >
            <div class="flex items-center">
                <input
                    id="default-checkbox"
                    type="checkbox"
                    bind:checked={timokatalogos.auth}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                />
                <label
                    for="default-checkbox"
                    class="ml-2 text-sm font-medium text-gray-900"
                    >Είναι μόνο για συνδεδεμένους</label
                >
            </div>
            <button
                on:click={saveToDb}
                class="bg-red-600 rounded-md text-white text-xl p-1 mx-2 hover:bg-red-700 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                >Αποθήκευση</button
            >
        </div>
        {#if timokatalogos.data.length}
            <div
                class="grid grid-cols-5 gap-4"
                use:dndzone={{
                    items,
                    flipDurationMs,
                    dragDisabled,
                    dropTargetStyle,
                }}
                on:consider={handleDndConsider}
                on:finalize={handleDndFinalize}
            >
                {#each timokatalogos.data as subitem (subitem.id)}
                    <div
                        class="relative"
                        animate:flip={{ duration: flipDurationMs }}
                    >
                        <img
                            src="https://medil.gr/site_images/{subitem.image}"
                            class="h-48 w-48 rounded-lg"
                            alt=""
                        />
                        <button
                            on:mousedown={() => (dragDisabled = false)}
                            on:mouseup={() => (dragDisabled = true)}
                            class="absolute top-0 bg-blue-500 text-white p-2 rounded-full hover:bg-blue-800 m-2"
                            ><svg
                                style="width:24px;height:24px"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z"
                                />
                            </svg></button
                        >
                        <button
                            on:click={() => {
                                dialogShowAdd = true;
                                imageExist = subitem.image;
                                catalogName = subitem.name;
                                editId = subitem.id;
                                reqPdf = false;
                                pdf[0] = { name: subitem.path };
                                timokEidikotites = subitem.cats;
                            }}
                            class="absolute top-10 bg-green-500 text-white p-2 rounded-full hover:bg-green-800 m-2"
                            ><svg
                                style="width:24px;height:24px"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                                />
                            </svg></button
                        >
                        <button
                            on:click={() => deleteCatalog(subitem.id)}
                            class="absolute top-20 bg-red-500 text-white p-2 rounded-full hover:bg-red-800 m-2"
                            ><svg
                                style="width:24px;height:24px"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
                                />
                            </svg></button
                        >
                        <a
                            href="/api/v1/settings/private/{subitem.path}"
                            download="catalog"
                        >
                            <button
                                class="absolute top-32 bg-orange-500 text-white p-2 rounded-full hover:bg-orange-800 m-2"
                                ><svg
                                    style="width:24px;height:24px"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
                                    />
                                </svg></button
                            >
                        </a>
                        {subitem.name}
                    </div>
                {/each}
            </div>
        {:else}
            Δεν υπάρχουν κατάλογοι...
        {/if}
    {:else}
        Δεν βρέθηκαν καταχωρήσεις...
    {/if}
{/await}

<Snackbar
    class="flex-column red text-white"
    bind:active={snackbar}
    bottom
    center
    timeout={3000}
>
    Υπήρξε προβλημα με το αρχείο του καταλόγου!
</Snackbar>
