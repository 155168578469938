<script>
    import {
        Row,
        Col,
        TextField,
        Button,
        Switch,
        Select as Sel,
    } from "svelte-materialify";
    export let resj = null;
    const items2 = ["Admin", "SuperUser", "Client"];
    const items = ["Client", "Supplier", "Both"];
    let sales, first_sale;

    const paymentTypes = [
        { name: "Αντικαταβολή", value: 1 },
        { name: "Προπληρωμή-Κατάθεση", value: 2 },
        { name: "Πίστωση 7 ημερών", value: 3 },
        { name: "Πίστωση 30 ημερών", value: 4 },
        { name: "Δίμηνη επιταγή", value: 5 },
    ];

    async function resendMailVer() {
        const res = await fetch(
            `/api/v1/users/resend/emailverification?paraliptis=` + resj.email
        );
    }

    if(resj.payment_method==null){
        resj.payment_method=[];
    }

    if(resj.sales!=1){
        sales=((1-resj.sales)*100).toFixed();
    }else{
        sales=0;
    }
    if(resj.first_sale!=1){
        first_sale=((1-resj.first_sale)*100).toFixed();
    }else{
        first_sale=0;
    }
    $:if(sales!=resj.sales){
        resj.sales=(100-sales)/100;
    }
    $:if(first_sale!=resj.first_sale){
        resj.first_sale=(100-first_sale)/100;
    }
</script>

<Row>
    <Col cols={12} md={5} sm={6}>
        <Switch bind:checked={resj.email_verification}>Επιβαιβέωση Mail</Switch>
    </Col>
    <Col cols={12} md={4} sm={6}>
        <Switch bind:checked={resj.admin_aproval}>Admin approval</Switch>
    </Col>
    <Col cols={12} md={3} sm={6}>
        <TextField outlined bind:value={resj.catalog}>Catalog</TextField>
    </Col>
</Row>
<Row>
    <Col cols={12} md={3} sm={6}>
        <TextField bind:value={resj.lots_no}>Count Lot</TextField>
    </Col>
    <Col cols={12} md={3} sm={6}>
        <Switch bind:checked={resj.lots_show}>Lot Show</Switch>
    </Col>
    <Col cols={12} md={3} sm={6}>
        <Button class="red white-text" on:click={resendMailVer}
            >Επαναλ. Verif</Button
        >
    </Col>
    <Col cols={12} md={3} sm={6}>
        <TextField outlined bind:value={resj.create_date}
            >Sign up Date</TextField
        >
    </Col>
</Row>
<Row>
    <Col cols={12} md={6} sm={6}>
        <Sel outlined {items} bind:value={resj.type}>Τύπος</Sel>
    </Col>
    <Col cols={12} md={6} sm={6}>
        <Sel outlined items={items2} bind:value={resj.role}>Ρόλος</Sel>
    </Col>
</Row>
<Row>
    <Col cols={12} md={6} sm={6}>
        <TextField disabled outlined bind:value={resj.galaxy_id}
            >Καταχωρημένος στο Galaxy</TextField
        >
    </Col>

    <Col cols={12} md={6} sm={6}>
        <Sel
            items={paymentTypes}
            multiple
            outlined
            chips
            bind:value={resj.payment_method}>Τρόπος πληρωμής</Sel
        >
    </Col>
</Row>
<Row>
    <Col cols={12} md={6} sm={6}>
        <Switch bind:checked={resj.first_sale_complete}>Έγινε η πρώτη παραγγελία?</Switch>
    </Col>
    <Col cols={12} md={3} sm={6}>
        <TextField outlined bind:value={first_sale} type="number">
            <div slot="append">
                <svg style="width:24px;height:24px;" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M18.5,3.5L3.5,18.5L5.5,20.5L20.5,5.5M7,4A3,3 0 0,0 4,7A3,3 0 0,0 7,10A3,3 0 0,0 10,7A3,3 0 0,0 7,4M17,14A3,3 0 0,0 14,17A3,3 0 0,0 17,20A3,3 0 0,0 20,17A3,3 0 0,0 17,14Z" />
                </svg>
            </div>
            Έκπτωση 1ης φοράς
        </TextField
        >
    </Col>
    <Col cols={12} md={3} sm={6}>
        <TextField outlined bind:value={sales} type="number">
            <div slot="append">
                <svg style="width:24px;height:24px;" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M18.5,3.5L3.5,18.5L5.5,20.5L20.5,5.5M7,4A3,3 0 0,0 4,7A3,3 0 0,0 7,10A3,3 0 0,0 10,7A3,3 0 0,0 7,4M17,14A3,3 0 0,0 14,17A3,3 0 0,0 17,20A3,3 0 0,0 20,17A3,3 0 0,0 17,14Z" />
                </svg>
            </div>
            Μόνιμη έκπτωση
        </TextField>
    </Col>
</Row>
<div class="mb-24">

</div>
