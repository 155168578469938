<script>
    import { user_logon, user_role, modal } from "./comp/utils/stores.js";
    import { onMount } from "svelte";
    import { flip } from "svelte/animate";
    import {
        Button,
        TextField,
        List,
        Subheader,
        ListItemGroup,
        ListItem,
        Checkbox,
        Avatar,
        MaterialApp,
        Row,
        Icon,
    } from "svelte-materialify";
    import {
        NotificationDisplay,
        notifier,
    } from "@beyonk/svelte-notifications";
    import router from "page";
    import Modal, { getModal } from "./comp/utils/Modal.svelte";
    import { mdiDelete, mdiPencil } from "@mdi/js";
    import CategoryE from "./comp/categories/Category.svelte";
    import CategoryC from "./comp/categories/CetegoryCreate.svelte";

    if (!$user_logon && $user_role != "Admin") {
        // console.log($user_logon + "  " + $user_role);
        router.redirect("/");
    }

    export let params;
    // let param_id = params.id;
    // let firstid = 0;
    let oldParam = params;

    $: if(params != oldParam){
        fc(params.id);
        oldParam=params;
    }

    let list = [];
    // console.log(apiUrl);
    // console.log(param_id);
    onMount(async () => {
        fc(params.id);
    });

    async function fc(i){
        const response = await fetch("/api/v1/shop/categories/childs/" + i);
        list = await response.json();
    }

    async function reload_cats(i){
        console.log('Reload! '+i)
        const response = await fetch(apiUrl);
        list = await response.json();
    }

    let hovering = false;

    const drop = (event, target) => {
        event.dataTransfer.dropEffect = "move";
        const start = parseInt(event.dataTransfer.getData("text/plain"));
        const newTracklist = list;

        if (start < target) {
            newTracklist.splice(target + 1, 0, newTracklist[start]);
            newTracklist.splice(start, 1);
        } else {
            newTracklist.splice(target, 0, newTracklist[start]);
            newTracklist.splice(start + 1, 1);
        }
        list = newTracklist;
        hovering = null;
    };

    const dragstart = (event, i) => {
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.dropEffect = "move";
        const start = i;
        event.dataTransfer.setData("text/plain", start);
    };

    async function reorder(relist) {
        if ($user_logon && $user_role == "Admin") {
            let order = "";
            for (let z = 0; z < relist.length; z++) {
                if (z == 0) {
                    order += relist[z].id;
                } else {
                    order += "," + relist[z].id;
                }
            }
            try {
                console.log(params.id);
                const res = await fetch(
                    `/api/v1/shop/categories/reorder/` +
                        params.id +
                        `/` +
                        order,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            Authorization:
                                "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                            Accept: "application/json",
                        },
                    }
                );
                let json = await res.json();
                if (res.ok) {
                    // alert = true;
                    notifier.success(json.message, 4000);
                }
            } catch (error) {
                notifier.warning("Something went wrong...", 4000);
            }
        } else {
            notifier.warning("Πρέπει να συνδεθείτε.", 4000);
        }
    }

    let category_name = "";
    let json = null;
    let y = 0;
    let cat_check = 0;
    let checked = [];
    let cat_id_edit;

    async function addcat(cat) {
        if (cat != undefined) {
            cat_check = list.findIndex((arr) => arr.id == cat.id);
            if (cat_check != -1) {
                // list.splice(cat_check, 1);
                notifier.info(
                    "Η κατ: " + cat.category_name + " υπάρχει ήδη!",
                    4000
                );
                // checked.push(json.findIndex(arr => arr.id == cat.id));
                // deletecat(cat.id)
            } else {
                const res_add = await fetch(
                    `/api/v1/shop/categories/add_to_category/` + params.id +'/'+ cat.id,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            Authorization:
                                "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                            Accept: "application/json",
                        },
                    }
                );
                let json=await res_add.json();
                if(json.message==true){
                    notifier.success(
                            "Η κατ: " + cat.category_name + " προστέθηκε.",
                            3000
                        );
                        list.push(cat);
                } else{
                    notifier.info(
                            "Η κατ: " + list[dcat].category_name + " ΔΕΝ αφαιρέθηκε.",
                            3000
                        );
                }
            }
            list = list;
        }
    }

    async function deletecat(dcat) {
        const res_del = await fetch(
                    `/api/v1/shop/categories/`+params.id+`/`+dcat,
                    {
                        method: "DELETE",
                        credentials: "include",
                        headers: {
                            Authorization:
                                "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                            Accept: "application/json",
                        },
                    }
                );
        let json=await res_del.json();
        if(json.message==true){
            for (let j = 0; j < list.length; j++) {
                if (list[j].id == dcat) {
                    notifier.danger("Η κατ: " + list[j].category_name + " αφαιρέθηκε.", 3000);
                    list.splice(j, 1);
                    list = list;
                    break;
                }
            }
        } else{
            notifier.info(
                    "Η κατηγορία ΔΕΝ αφαιρέθηκε.",
                    3000
                );
        }

    }

    async function search_category(category_name) {
        json = null;
        checked = [];
        if (category_name.length < 3) {
            json = null;
            checked = [];
        }
        if (category_name.length > 2) {
            json = null;
            checked = [];
            try {
                const res = await fetch(
                    `/api/v1/shop/search/category/` + category_name
                );
                json = await res.json();
                if (res.ok) {
                    for (let i = 0; i < list.length; i++) {
                        y = json.findIndex((arr) => arr.id == list[i].id);
                        if (y != -1) {
                            checked.push(y);
                        }
                    }
                } else {
                    checked = [];
                    json = null;
                }
            } catch (error) {
                checked = [];
                json = null;
            }
        }
    }

    function create_edit_category(id) {
        cat_id_edit = id;
    }

    // function create_edit_category(){
    //     cat_create= true;
    // }

    // $: console.log(`the count is ${cat_id_edit}`);
</script>

<NotificationDisplay />

<div class="container mx-auto px-4 pb-4">
    <h3 class="py-4">Κατηγορίες προϊόντων:</h3>

    <Button class="red white-text" on:click={reorder(list)}>Αποθηκευση</Button>
    <span
        ><Button class="blue white-text" on:click={() => getModal().open()}
            >Προσθηκη υπαρχουσας κατηγοριας</Button
        ></span
    >
    <span
        ><Button
            class="green white-text"
            on:click={() => getModal("create").open(reload_cats)}
            >Προσθηκη καινουριας κατηγοριας</Button
        ></span
    >

    <Modal>
        <div class="h-96 w-auto">
            <MaterialApp>
                <!-- opening a model with an `id` and specifying a callback	 -->
                <Row style="margin-left: 4px;margin-right: 4px;">
                    <TextField
                        filled
                        bind:value={category_name}
                        on:input={search_category(category_name)}
                        >Όνομα κατηγορίας</TextField
                    >
                    <Button
                        fab
                        size="default"
                        class="blue white-text"
                        on:click={reorder(list)}>Add</Button
                    >
                </Row>
                <div class="d-flex justify-center">
                    <List class="elevation-2" style="width:500px">
                        {#if json}
                            <ListItemGroup multiple bind:value={checked}>
                                {#each json as cat, i}
                                    <ListItem on:click={addcat(cat)}>
                                        <span slot="prepend">
                                            <Checkbox
                                                checked={checked.includes(i)}
                                            />
                                            <!-- bind:group1
                                            value={cat}
                                            on:change={addcat(cat)}-->
                                        </span>
                                        {cat.category_name}
                                        <span slot="subtitle">
                                            Id: {cat.id} Τύπος κατ.: {cat.category_level}
                                        </span>
                                        <span slot="append">
                                            <Avatar
                                                ><img
                                                    src="https://medil.gr/site_images/{cat.category_image}"
                                                    alt={cat.category_slug}
                                                /></Avatar
                                            >
                                        </span>
                                    </ListItem>
                                {/each}
                            </ListItemGroup>
                        {/if}
                    </List>
                </div>
            </MaterialApp
            >
        </div>
    </Modal>

    <Modal id="second">
        {#if cat_id_edit != null}
            <CategoryE id={cat_id_edit} />
        {/if}
    </Modal>

    <Modal id="create">
        <!-- {#if cat_create!= null} -->
        <CategoryC pid={params.id}/>
        <!-- {/if} -->
    </Modal>

    <div
        class="grid grid-cols-1 md:grid-cols-5 grid-flow-row auto-rows-max gap-4 md:auto-rows-min pt-10"
    >
        {#each list as item, index (item.id)}
            <div
                class="list-item"
                style="list-style: none;"
                animate:flip
                draggable={true}
                on:dragstart={(event) => dragstart(event, index)}
                on:drop|preventDefault={(event) => drop(event, index)}
                ondragover="return false"
                on:dragenter={() => (hovering = index)}
                class:is-active={hovering === index}
            >
                <div
                    class="h-full image bg-white border-4 border-transparent rounded-lg shadow-lg overflow-hidden hover:shadow-2xl hover:scale-105 transition-all transform duration-300 hover:border-blue-400"
                >
                    {#if item.category_level == "products"}
                        <a href="/admin_med/products/{item.id}/{item.category_slug}">
                            <img
                                class="object-cover w-full h-64"
                                src="https://medil.gr/site_images/{item.category_image}"
                                alt={item.category_name}
                            />
                            <span
                                class="text-center text-sm block font-semibold py-2 px-6 text-gray-600 hover:text-blue-900"
                                >{item.category_name}</span
                            >
                        </a>
                    {:else}
                        <!-- target="_self" -->
                        <!-- on:click={fetch_next_cats(item.id)} -->
                        <a href="/admin_med/shop/{item.id}/{item.category_slug}">
                            <img
                                class="object-cover w-full h-64"
                                src="https://medil.gr/site_images/{item.category_image}"
                                alt={item.category_name}
                            />
                            <span
                                class="text-center text-sm block font-semibold py-2 px-6 text-gray-600 hover:text-blue-900"
                                >{item.category_name}</span
                            >
                        </a>
                    {/if}
                    <div class="absolute top-0 right-0 p-2">
                        <Button
                            fab
                            size="small"
                            class="orange white-text"
                            on:click={create_edit_category(item.id)}
                            on:click={() => getModal("second").open()}
                        >
                            <Icon path={mdiPencil} />
                        </Button>
                        <Button
                            fab
                            size="small"
                            class="red white-text"
                            on:click={deletecat(item.id)}
                        >
                            <Icon path={mdiDelete} />
                        </Button>
                    </div>
                </div>
            </div>
        {/each}
    </div>
</div>
