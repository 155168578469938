<script>
	import { Map, Marker } from "@beyonk/svelte-mapbox";

    let mapComponent;
	const accessToken = "pk.eyJ1IjoiYW5kcmVtaWNoIiwiYSI6ImNrb3ZjOGd2ejAwcWcybnJzZjczd2I1M2cifQ.UYYUFS0JmxiSU4PPu9W3JQ";
    export let resj=null;

	function onReady(lng, lat) {
        mapComponent.setCenter([lng,lat])
		// mapComponent.flyTo({ center: [lng, lat] });
		mapComponent.addControl(new mapboxgl.FullscreenControl());
	}
    
</script>

<!-- <hr />	 -->

<div class="bg-white w-[550px] h-[550px] rounded mx-auto mt-4">
			
    <Map
        {accessToken}
        style="mapbox://styles/mapbox/streets-v11"
        bind:this={mapComponent}
        on:ready={onReady(resj.lng, resj.lat)}				
        zoom="12"
    >
        {#each resj.addresses as marker}
        {#if marker.lat != "0.0000000000000000" && marker.lat != null}
            <Marker
                lat={marker.lat}
                lng={marker.lng}
                label={resj.first_name + " " + resj.last_name}
                color="red"
            />
        {/if}
        {/each}
        <!-- {#if resj.lat != "0.0000000000000000" && resj.lat != null}
            <Marker
                lat={resj.lat}
                lng={resj.lng}
                label={resj.first_name + " " + resj.last_name}
                color="green"
            />
        {/if} -->
    </Map>

</div>