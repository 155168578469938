<script>
    import { Dialog, MaterialApp } from "svelte-materialify";
    import { onMount } from "svelte";
    let activeCopyDialog = false;
    let cats = [];
    onMount(async () => {
        await fetchCats();
    });
    async function fetchCats() {
        const res = await fetch("/api/v1/shop/categories");
        cats = await res.json();
    }
    // $: console.log(cats[0]);
    async function deleteFilters(id){
        const res_category_update = await fetch(
            "/api/v1/shop/categories/" + id,
            {
                method: "PATCH",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                        "Content-Type": "application/json",
                        Accept: "application/json"
                },
                body: JSON.stringify({
                    filters: null
                }),
            }
        );
    }
</script>

<div class="container mx-auto h-full">
    <div class="flex my-2 space-x-2">
        <a
            href="/productsaggregations/create"
            class="bg-green-700 text-white rounded p-2"
            >Δημιουργία ομάδας</a
        >
    </div>
    <div class="font-semibold text-xl my-4">Κατηγορίες με φίλτρα</div>
    <div class="flex flex-wrap space-x-2 space-y-2">
        {#each cats as cat}
            {#if cat.filters != null && cat.filters.length}
                <div
                    class="flex p-2 bg-blue-100 rounded shadow w-fit items-center space-x-2"
                >
                    <a href='/productsaggregations/{cat.id}/editteam' class="flex space-x-2">
                        <div>{cat.category_name}</div>
                        <div class="bg-orange-500 rounded-full px-1 text-white">
                            {cat.filters.length}
                        </div>
                    </a>
                    <button class="bg-red-500 rounded-full p-1 text-white" on:click={deleteFilters(cat.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                          </svg>
                    </button>
                </div>
            {/if}
        {/each}
    </div>
</div>
