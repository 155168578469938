<script>
    import { onMount } from 'svelte';
    // import MultiSelect from "svelte-multiselect";
    import SelectComp from 'svelte-select';
    import ProggressCircular from "../utils/ProggressCircular.svelte";

    // let selected=[];
    export let pid = null;
    export let selectedValues=[];
    let c_json = null;

    // $: console.log(selectedValues)

    onMount(async () => {
        let cats = categories_fetch();
        // console.log(cats);
	});

    async function categories_fetch() {
        const c_fetch = await fetch(
            "/api/v1/shop/categories/childs/top&sub",
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    Accept: "application/json",
                },
            }
        );
        if (c_fetch.ok) {
            c_json = await c_fetch.json();
            for(let i=0; i<c_json.length; i++){
                // console.log(pid);
                if(c_json[i].value==pid){
                    selectedValues.push(c_json[i]);
                    // console.log(selected);
                }
            }
            return c_json;
        } else {
            throw new Error(c_fetch.message);
        }
    }

</script>

{#if c_json != null}
<!-- <MultiSelect
    bind:selected
    bind:selectedValues
    options={c_json}
/> -->
<SelectComp items={c_json} isMulti={true} bind:value={selectedValues} isCreatable={false} placeholder='Γονικές κατηγορίες'/>       
{:else}
<ProggressCircular />
{/if}