<script>
	import {Row,
		Col,
		TextField,
	} from "svelte-materialify";
	// import { param, customerId, googleApiKey } from "../utils/stores.js";
    import SelectMulti from 'svelte-select';

	// let mapComponent;
    // const accessToken = "pk.eyJ1IjoiYW5kcmVtaWNoIiwiYSI6ImNrb3ZjOGd2ejAwcWcybnJzZjczd2I1M2cifQ.UYYUFS0JmxiSU4PPu9W3JQ";
    

	// function onReady(lng, lat) {
	// 	mapComponent.flyTo({ center: [lng, lat] });
	// 	mapComponent.addControl(new mapboxgl.FullscreenControl());
	// }


	
    export let resj = null;
    
    


const idikotites = [
        "Μικροβιολόγοι-Βιοπαθολόγοι",
		"Αγγειοχειρουργοί - Αγγειολόγοι",
		"Αθλητίατροι",
		"Αιματολόγοι",
		"Αλλεργιολόγοι",
		"Αναισθησιολόγοι",
		"Βελονιστές",
		"Γαστρεντερολόγοι",
		"Γενικοί Γιατροί",
		"Γενικοί Χειρουργοί",
		"Γιατροί Υπερβαρικής - Καταδυτικής Ιατρικής",
		"Γναθοχειρουργοί",
		"Γυναικολόγοι - Μαιευτήρες",
		"Δερματολόγοι - Αφροδισιολόγοι",
		"Διαβητολόγοι",
		"Διαιτολόγοι - Διατροφολόγοι",
		"Εμβρυολόγοι",
		"Ενδοδοντολόγοι",
		"Ενδοκρινολόγοι",
		"Εργοθεραπευτές",
		"Ηπατολόγοι",
		"Καρδιολόγοι",
		"Καρδιοχειρουργοί",
		"Μαστολόγοι",
		"Νευρολόγοι",
		"Νευροχειρουργοί",
		"Νευροψυχολόγοι",
		"Νεφρολόγοι",
		"Ογκολόγοι",
		"Οδοντίατροι",
		"Ορθοδοντικοί",
		"Ορθοπαιδικοί και Ορθοπαιδικοί Χειρουργοί",
		"Ουρολόγοι - Ανδρολόγοι",
		"Οφθαλμίατροι",
		"Παθολόγοι",
		"Παιδίατροι",
		"Παιδογαστρεντερολόγοι",
		"Παιδοδιαβητολόγοι",
		"Παιδοδοντίατροι",
		"Παιδοενδοκρινολόγοι",
		"Παιδοκαρδιολόγοι",
		"Παιδοορθοπαιδικοί",
		"Παιδοπνευμονολόγοι",
		"Παιδορευματολόγοι",
		"Παιδοχειρουργοί",
		"Παιδοψυχίατροι",
		"Παιδοψυχολόγοι",
		"Παιδο-ΩΡΛ",
		"Περιοδοντολόγοι",
		"Πλαστικοί Χειρουργοί",
		"Πνευμονολόγοι - Φυματιολόγοι",
		"Ποδολόγοι",
		"Προσθετολόγοι",
		"Ρευματολόγοι",
		"Στοματολόγοι",
		"Φυσίατροι",
		"Φυσικοθεραπευτές",
		"Χειρουργοί Ενδοκρινών Αδένων",
		"Χειρουργοί στόματος",
		"Ψυχίατροι",
		"Ωτορινολαρυγγολόγοι (ΩΡΛ)",
	];


</script>

<!-- {#if !resj}
	<div class="flex">
		<ProgressCircular size={70} width={7} indeterminate color="success" />
		<p>...waiting</p>
	</div>
{:else}	 -->
        <!-- <form name="create_user" on:submit|preventDefault={fetchCord(resj)}>		 -->
            <!-- <Overlay absolute bind:active={activeOverlay} /> -->
            <Row>
                <Col>
                    <TextField required outlined bind:value={resj.username} id='organization'>Επωνυμία</TextField>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextField required outlined bind:value={resj.price_tag}
                        >Δραστηριότητα</TextField
                    >
                </Col>
            </Row>
            <Row>
                <Col cols={12} md={3}>
                    <TextField required outlined bind:value={resj.vat}>ΑΦΜ</TextField>
                </Col>
                <Col cols={12} md={3}>
                    <TextField required outlined bind:value={resj.doy}>ΔΟΥ</TextField>
                </Col>
                <Col cols={12} md={6}>
                    <!-- <TextField solo bind:value={category}>Ειδικότητα</TextField> -->
                    <div class="themed">
                        <SelectMulti items={idikotites} isMulti={true} bind:value={resj.category} placeholder='Ειδικότητα' isCreatable={true}>
                            Ειδικότητα
                        </SelectMulti>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col cols={12} md={6}>
                    <TextField required outlined bind:value={resj.first_name} id='name1'>Όνομα</TextField>
                </Col>
                <Col cols={12} md={6}>
                    <TextField required outlined bind:value={resj.last_name} id='additional-name'>Επώνυμο</TextField>
                </Col>
    
            </Row>
            <Row>
                <Col cols={12} md={6}>
                    <TextField required outlined bind:value={resj.address} id='street-address'>Διέυθυσνη</TextField>
                </Col>
                <Col cols={12} md={3}>
                    <TextField required outlined bind:value={resj.numbe}>Αριθμός</TextField>
                </Col>
                <Col cols={12} md={3}>
                    <TextField required outlined bind:value={resj.postal} id='postal-code'>T.K.</TextField>
                </Col>
            </Row>
            <Row>
                <Col cols={12} md={4}>
                    <TextField required outlined bind:value={resj.perioxi}>Περιοχή-Πόλη</TextField>
                </Col>
                <Col cols={12} md={4}>
                    <TextField outlined bind:value={resj.nomos}>Νομός</TextField>				
                </Col>
                <Col cols={12} md={4}>
                    <TextField outlined bind:value={resj.country}>Χώρα</TextField>
                </Col>
            </Row>
            <Row>
                <Col cols={12} md={6} sm={6}>
                    <TextField required outlined bind:value={resj.email} id='email'>Email</TextField>
                </Col>
                <Col cols={12} md={3} sm={6}>
                    <TextField required outlined bind:value={resj.tel1} id='tel'>Τηλέφωνο</TextField>
                </Col>
                <Col cols={12} md={3} sm={6}>
                    <TextField required outlined bind:value={resj.tel2} id='mobile'>Κινητό</TextField>
                </Col>
            </Row>
            <!-- <Row>
                <Col cols={12} md={3} sm={6}>
                    <Switch bind:checked={resj.email_verification} >Conf. Mail</Switch>
                </Col>
                <Col cols={12} md={5} sm={6}>
                    <Switch bind:checked={resj.admin_aproval} >Admin approval</Switch>
                </Col>
                <Col cols={12} md={4} sm={6}>
                    <TextField  outlined bind:value={resj.catalog} >Catalog</TextField>
                </Col>
            </Row>
            <Row>
                <Col cols={12} md={3} sm={6}>
                    <TextField bind:value={resj.lots_no} >Count Lot</TextField>
                </Col>
                <Col cols={12} md={3} sm={6}>
                    <Switch bind:checked={resj.lots_show} >Lot Show</Switch>
                </Col>
				<Col cols={12} md={3} sm={6}>
                    <Button class="red white-text" on:click={resendMailVer}>Επαναλ. Verif</Button>
                </Col>
                <Col cols={12} md={3} sm={6}>
                    <TextField  outlined bind:value={resj.create_date} >Sign up Date</TextField>
                </Col>
            </Row> -->            
        <!-- </form> -->

<!-- {/if} -->