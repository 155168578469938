<script>
    import { TextField, Row, Container, Icon, MaterialApp, ListItemGroup, List, ListItem, Radio, Button} from 'svelte-materialify';
    import { mdiBarcode, mdiPlus } from '@mdi/js';
    import Modal, { getModal } from "../utils/Modal.svelte";
    import ProductTabs from './ProductTabs.svelte';

    let html;
    let search_string='';
    const url = '/api/v1/shop/search/gxproduct/';
    let gx_json = null;
    let group;
    let id_transfer = null;
    let intro_modal = false;

    function modal_edit_visible(){
        if(intro_modal){
            intro_modal=false;
        }else{
            intro_modal=true;
        }
        
    }


    async function product_info(search_string){
        group = null;
        gx_json = null;
        if (search_string.length > 2){
        const res_gx_table = await fetch(
            url+search_string,
            {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            Authorization: "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                            Accept: "application/json",
                        },
                    }
        );
        gx_json = await res_gx_table.json();
        }
    }
    

    function select_product(id_input){
        id_transfer=id_input;
    }
</script>

<Modal id="edit_product" minwidth='75%'>
    {#if id_transfer!=null && intro_modal}
        <ProductTabs gxidTab={id_transfer} />
    {/if}
</Modal>    

  <div class="h-96 w-auto">
    <Container>
    <MaterialApp>
        <Row style="margin-left: 4px;margin-right: 4px;">
                <TextField filled hint="Αναζητήστε με ονομα ή κωδικό ή με Galaxy κωδικό προιόντος"
                    bind:value={search_string}
                    on:input={product_info(search_string)}>
                        <div slot="prepend">
                        <Icon path={mdiBarcode } />
                        </div>
                        Αναζήτηση
                </TextField>
        </Row>
        <div class="d-flex justify-center">
            {#if gx_json!=null}
             <List class="elevation-2" >                <!--style="width:500px" -->
                    <ListItemGroup  bind:value={group}>
                        {#each gx_json as item, i}
                            <ListItem on:click={select_product(item.gx_id)}>
                                <span slot="prepend">
                                    <Radio bind:group value={i}/>
                                </span>
                                {item.gx_desc}
                                <span slot="subtitle">
                                    Id: {item.gx_code} Galaxy: {item.gx_id}
                                </span>
                                <span slot="append">
                                    {item.balance}
                                </span>
                            </ListItem>
                        {/each}
                    </ListItemGroup>
            </List>
            {/if}
            {#if group!=null}
            <div class="absolute bottom-0 right-0 p-6">
                <Button fab size="x-large" class="indigo white-text" on:click={()=>getModal('edit_product').open(modal_edit_visible)} on:click={modal_edit_visible}>
                    <Icon size="28px" path={mdiPlus } />
                  </Button>
            </div>
            {/if}
        </div>
        </MaterialApp>
    </Container>
</div>

<!-- <Editor {html} on:change={(evt)=>html = evt.detail}/> -->
