<script>
    import {flip} from "svelte/animate";
    import {dndzone} from "svelte-dnd-action";
    import SearchProduct from "./SearchProduct.svelte";
    import { Dialog, Radio, Button, TextField } from "svelte-materialify";
    export let gxid = null;
    export let idb = null;
    export let product = null;
    let active = false;
    product.id=product.id_product;
    let items = [];
    items.push(product);    
    let activeTeamProducts = false; 
    const flipDurationMs = 300;
    const dropTargetStyle={};
    let teamName="";
    let obj;
    let image=false;
    let group = false;
    let showSpinner=false;
    let dragDisabled=true;
    console.log("product",product)
    // const fetchTeam = async () =>{
    //     await fetch('/api/v1/shop/product/')
    // }

    if(product.other_products!=null){
        activeTeamProducts=true;
        items=product.other_products.items;
        teamName=product.other_products.name;
        group=product.other_products.all;
        image=product.other_products.showImage;
    }
    
    async function setTeam(){
        for(let i=0; i<items.length; i++){
            try{
                const {category_product, second_images_product, docs_product, product_atribuutes, product_tags, multi_price, create_date, update_date, ...rest} = items[i];
                items[i]=rest
            }catch(error){}
        }
        if(activeTeamProducts){
            obj = {name: teamName, all: group, delete: false, showImage: image, id:product.id_product, items: items};
        }else{
            obj = {name: teamName, all: group, delete: true, showImage: image, id:product.id_product, items: items};
        }
        const res = await fetch('/api/v1/shop/product/setteam',{
            method: 'POST',
            headers: {
                  "Content-Type": "application/json"
                        },
            body: JSON.stringify(obj)
        });
        if (res.ok){
            showSpinner=false;
        }
    }
    function handleDndConsider(e) {
        items = e.detail.items;
    }
    function handleDndFinalize(e) {
        items = e.detail.items;
        console.log("items",items);
    }
    function deleteProduct(i){
        console.log("id",i);
        items.splice(items.findIndex((arr) => arr.id_product == i), 1);
        items = items;
    }
</script>

<Dialog bind:active class="white p-2">
    <!-- {#if cat_id_edit!= null} -->
    <SearchProduct bind:list={items}/>
    <!-- {/if} -->
</Dialog>

<div class="flex justify-around p-4">
<Button on:click={() => activeTeamProducts ? activeTeamProducts=false : activeTeamProducts=true} class="white-text green">
    {activeTeamProducts?"Διαγραφή επιλογών":"Ενεργοποιηση επιλογων"}
</Button>
{#if activeTeamProducts}
<Button on:click={() => (active = true)} class="white-text indigo">
    Προσθηκη προιοντων
</Button>
{/if}
<Button on:click={()=>{setTeam(); showSpinner=true;}} class="white-text red">
    {#if showSpinner}
    <div role="status">
        <svg class="inline mr-2 w-4 h-4 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
    </div>
    {/if}
    Αποθηκευση
</Button>
</div>
{#if activeTeamProducts}
    <TextField outlined class="px-8 pt-4" bind:value={teamName}>Περιγραφή ομάδας</TextField>
    <div class="flex justify-between">
        <div class="min-h-[300px] flex flex-col items-start mx-8" use:dndzone="{{items, flipDurationMs, dropTargetStyle, dragDisabled}}" on:consider="{handleDndConsider}" on:finalize="{handleDndFinalize}">
            {#each items as item(item.id)}
            <div class="w-auto flex items-center bg-blue-100 rounded-lg my-2" animate:flip="{{duration: flipDurationMs}}">
                <div class="cursor-move" on:mouseover={()=>dragDisabled=false} on:mouseleave={()=>dragDisabled=true}>
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z" />
                    </svg>
                </div>
                <div class="w-96 text-lg text-gray-700 px-4 line-clamp-2">
                    {item.name_product}
                </div>        
                <div class="w-48">
                    <TextField dense outlined maxlength="11" hint="Σύντομο εως 11 χαρακτήρες" class="pt-4" bind:value={item.shortName}>Όνομα</TextField>
                </div>
                <div class="px-4 cursor-pointer" on:click={()=> deleteProduct(item.id_product)}>
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                    </svg>
                </div>
            </div>
            {/each}
        </div>
        <div class="text-center text-lg font-semibold text-gray-600">
            <div class="">
                Θέλετε να γίνει εφαρμογή των <span class="font-bold">επιλογών και της περιγραφής ομάδας</span> σε όλα τα υποκείμενα προϊόντα;
                <div class="flex justify-center my-4">
                    <div class="mr-4 p-3 bg-green-50 rounded-lg">
                        <Radio bind:group value={true} color="success">ΝΑΙ</Radio>
                    </div>
                    <div class="p-3 bg-red-50 rounded-lg">
                        <Radio bind:group value={false} color="red">ΟΧΙ</Radio>
                    </div>
                </div>
            </div>
            <div class=" border-t-gray-600">
                Θέλετε να εφανίζονται οι εικόνες στις επιλογές των προϊόντων;
                <div class="flex justify-center my-4">
                    <div class="mr-4 p-3 bg-green-50 rounded-lg">
                        <Radio bind:group={image} value={true} color="success">ΝΑΙ</Radio>
                    </div>
                    <div class="p-3 bg-red-50 rounded-lg">
                        <Radio bind:group={image} value={false} color="red">ΟΧΙ</Radio>
                    </div>
                </div>
            </div>
        </div>
    </div>
{:else}
<div class="w-full text-center text-gray-700 p-8 font-semibold text-lg">
    Η επιλογές προιόντων είναι απενεργοποιημένη.
</div>
{/if}

