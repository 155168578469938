import { writable } from 'svelte/store';
// import { writable as localstorage } from 'svelte-local-storage-store';

export const modal = writable(null);

export const count = writable("Bearer ");
export const param = writable("8UKDHJ_medil_by_andre_koukouroukou 56526658741");

export const mapToken = ("pk.eyJ1IjoiYW5kcmVtaWNoIiwiYSI6ImNrb3ZjOGd2ejAwcWcybnJzZjczd2I1M2cifQ.UYYUFS0JmxiSU4PPu9W3JQ");

export const customerId = writable(0);

export const googleApiKey = ("AIzaSyDcBRTETxKbXhGGVlp_P6HviRGAUjMc0FA");

export const filterMarkersName = writable([]);
export const filterMarkersCategory = writable([]);
export const filterMarkerslat = writable([]);
export const filterMarkerslng = writable([]);

export const user_logon = writable(false);
export const user_role = writable("client");

// export const cart = writable(localStorage.getItem("cart") || "");

// cart.subscribe(val => localStorage.setItem("cart", val));

export const fin_cart_products = writable(JSON.parse(localStorage.getItem("cart")) || []);

export let urlCatId = writable(0);

export let catName= writable(null);