<script>
	import { Map, Marker } from "@beyonk/svelte-mapbox";
    import { mapToken, filterMarkersName, filterMarkersCategory, filterMarkerslat, filterMarkerslng } from "../utils/stores.js";

	// export let nameMarker = [];
	// export let lat = [];
	// export let lng = [];
	// export let category = [];

	let mapComponent;
	const accessToken = mapToken;

	function onReady() {
		mapComponent.flyTo({ center: [25.797, 38.415] });
		mapComponent.addControl(new mapboxgl.FullscreenControl());
	}



</script>

		<div class="bg-white w-100 h-60 rounded shadow-md card">
			

                <Map
                        {accessToken}
                        style="mapbox://styles/mapbox/outdoors-v11"
                        bind:this={mapComponent}
                        on:ready={onReady}
                        zoom="4"
                    >
                        {#each $filterMarkersName as user, i}
                            {#if $filterMarkerslat[i] != "0.0000000000000000" && $filterMarkerslat[i] != null && $filterMarkerslat[i] != ""}
                                <Marker
                                    
                                    lat={$filterMarkerslat[i]}
                                    lng={$filterMarkerslng[i]}
                                    label={$filterMarkersCategory[i] +
                                        " " +
                                        $filterMarkersName[i]}
                                    color="red"
                                />
                            {/if}
                        {/each}
                    </Map>

		</div>