<script>
    import { Button, ProgressCircular, Select, Col, Row, Dialog, MaterialApp } from "svelte-materialify";
    import { count, param, customerId, modal, filterMarkersName, filterMarkersCategory, filterMarkerslat, filterMarkerslng } from "./comp/utils/stores.js";
    // import Modal from "./components/Modal.svelte";
    import ShowUserDetails from "./comp/users/ShowUserDetails.svelte";
    import PopupCreateUser from "./comp/users/PopupCreateUser.svelte"; //AFM DATA
    import PopupMap from "./comp/users/PopupMap.svelte";
    import Afm from "./comp/users/Afm.svelte";
    import {onMount} from 'svelte';
    // import {nameMarker, lat, lng, category} from "./components/PopupMap.svelte";

    const options = [
    {component: ShowUserDetails  },
    {component: PopupCreateUser },
    {component: PopupMap  },
    {component: Afm  },
    ];

    const showPopup = (rowid) => {
        customerId.set(rowid);
        // modal.set(Popup);
        selected=options[0];
        showDialog=true;
    };

    export const title = 'Χρήστες';
    let showDialog=false;
    let selected = options[0];

    // const createPopup = () => {
        //     modal.set(PopupCreateUser);
        // };

        // let mapComponent;
        // const accessToken = mapToken;

        // function onReady() {
        //     mapComponent.flyTo({ center: [25.797, 38.415] });
        //     mapComponent.addControl(new mapboxgl.FullscreenControl());
        // }

        // function showMap(filterMarkersName, filterMarkersCategory, filterMarkerslat, filterMarkerslng){
        //     // modal.set(bind(PopupMap, { name: filterMarkersName, category: filterMarkersCategory, lat: filterMarkerslat, lng: filterMarkerslng }));
        //     nameMarker = filterMarkersName;
        //     lat= filterMarkerslat;
        //     lng=filterMarkerslng;
        //     category=filterMarkersCategory;
        //     modal.set(PopupMap);
        // }

        // const showMap = () => {
        //     modal.set(PopupMap);
        //     // modal.set(bind(PopupMap, { name: filterMarkersName, category: filterMarkersCategory, lat: filterMarkerslat, lng: filterMarkerslng }));
        // };

        // var result = null;

        onMount(async () => {
            fetchusers();
        });

    let resj = null;

    async function fetchusers() {
        const res = await fetch("/api/v1/users", {      /// api/v1/users /api/v1/users 
        method: "GET",
    credentials: "include",
    headers: {
        Authorization: $param + $count,
    Accept: "application/json",
            },
        });
    resj = await res.json();
    // result = JSON.stringify(json);
    // resj = JSON.parse(result);
    Perioxes();

    if (res.ok) {
            return resj;
        } else {
            throw new Error();
        }
    }

    // var promise = fetchusers();

    function refreshClick() {
        fetchusers();
    }
    var uniquePerioxes = [];
    var uniqueCategory = [];
    var uniqueNomos = [];
    function Perioxes() {
        let i;
    for (i = 0; i < resj.length; i++) {
            if (uniquePerioxes.indexOf(resj[i].perioxi) === -1) {
        uniquePerioxes.push(resj[i].perioxi);
            }
    if (uniqueCategory.indexOf(resj[i].category) === -1) {
        uniqueCategory.push(resj[i].category);
            }
    if (uniqueNomos.indexOf(resj[i].nomos) === -1) {
        uniqueNomos.push(resj[i].nomos);
            }
        }
    uniquePerioxes.sort();
    uniqueCategory.sort();
    uniqueNomos.sort();
    }

    async function deleteRow(rowid) {
        var confirmWindow = confirm(
    "Πρόκειτε να διαγράψετε οριστικά τον χρήστη " + rowid
    );

    if (confirmWindow == true) {
            const deleteuser = await fetch(
    "/api/v1/users/id/" + rowid,
    {
        method: "DELETE",
    credentials: "include",
    headers: {
        Authorization: $param + $count,
    Accept: "application/json",
                    },
                }
    );

    if (deleteuser.ok) {
                var row = document.getElementById(rowid);
    var table = row.parentNode;
    while (table && table.tagName != "TABLE")
    table = table.parentNode;
    if (!table) return;
    table.deleteRow(row.rowIndex);
            } else {
                throw new Error();
            }
        }
    }

    function hideTableRow(rowid)
    {
        document.getElementById(rowid).style.display = 'none';
    }
    function showTableRow(rowid)
    {
        document.getElementById(rowid).style.display = '';
    }

    var perioxesFilter=[];
    var categoryFilter=[];
    var nomosFilter=[];
    var hiden=0;

    function hilander (){
    var filterMarkersName1 = [];
    var filterMarkersCategory1 = [];
    var filterMarkerslat1 = [];
    var filterMarkerslng1= [];

    if (perioxesFilter != 0 && categoryFilter != 0 && nomosFilter != 0){
        // console.log(perioxesFilter.length);
        for(let i=0; i<resj.length; i++){
        // if(!perioxesFilter.includes(resj[i].perioxi)){
        let id = resj[i].id;
    hideTableRow(id);
    // }

    if(perioxesFilter.includes(resj[i].perioxi) && categoryFilter.includes(resj[i].category) && nomosFilter.includes(resj[i].nomos)){
        let id = resj[i].id;
    showTableRow(id);
    hiden = 1;
    filterMarkersName1[i] = resj[i].last_name;
    filterMarkersCategory1[i] = resj[i].category;
    filterMarkerslat1[i] = resj[i].lat;
    filterMarkerslng1[i] = resj[i].lng;

            }
        }
    }else if (perioxesFilter != 0 && categoryFilter != 0 && nomosFilter == 0){
        for(let i=0; i<resj.length; i++){
        // if(!perioxesFilter.includes(resj[i].perioxi)){
        let id = resj[i].id;
    hideTableRow(id);
    // }
    if(perioxesFilter.includes(resj[i].perioxi) && categoryFilter.includes(resj[i].category)){
        let id = resj[i].id;
    showTableRow(id);
    hiden = 1;
    filterMarkersName1[i] = resj[i].last_name;
    filterMarkersCategory1[i] = resj[i].category;
    filterMarkerslat1[i] = resj[i].lat;
    filterMarkerslng1[i] = resj[i].lng;
            }
        }

    }else if (perioxesFilter != 0 && categoryFilter == 0 && nomosFilter != 0){
        for(let i=0; i<resj.length; i++){
        // if(!perioxesFilter.includes(resj[i].perioxi)){
        let id = resj[i].id;
    hideTableRow(id);
    // }
    if(perioxesFilter.includes(resj[i].perioxi) && nomosFilter.includes(resj[i].nomos)){
        let id = resj[i].id;
    showTableRow(id);
    hiden = 1;
    filterMarkersName1[i] = resj[i].last_name;
    filterMarkersCategory1[i] = resj[i].category;
    filterMarkerslat1[i] = resj[i].lat;
    filterMarkerslng1[i] = resj[i].lng;
            }
        }

    }else if (perioxesFilter == 0 && categoryFilter != 0 && nomosFilter != 0){
        for(let i=0; i<resj.length; i++){
        // if(!perioxesFilter.includes(resj[i].perioxi)){
        let id = resj[i].id;
    hideTableRow(id);
    // }
    if(categoryFilter.includes(resj[i].category) && nomosFilter.includes(resj[i].nomos)){
        let id = resj[i].id;
    showTableRow(id);
    hiden = 1;
    filterMarkersName1[i] = resj[i].last_name;
    filterMarkersCategory1[i] = resj[i].category;
    filterMarkerslat1[i] = resj[i].lat;
    filterMarkerslng1[i] = resj[i].lng;
            }
        }

    }else if (perioxesFilter != 0 && categoryFilter == 0 && nomosFilter == 0){
        for(let i=0; i<resj.length; i++){
        // if(!perioxesFilter.includes(resj[i].perioxi)){
        let id = resj[i].id;
    hideTableRow(id);
    // }
    if(perioxesFilter.includes(resj[i].perioxi)){
        let id = resj[i].id;
    showTableRow(id);
    hiden = 1;
    filterMarkersName1[i] = resj[i].last_name;
    filterMarkersCategory1[i] = resj[i].category;
    filterMarkerslat1[i] = resj[i].lat;
    filterMarkerslng1[i] = resj[i].lng;
            }
        }

    }else if (perioxesFilter == 0 && categoryFilter == 0 && nomosFilter != 0){
        for(let i=0; i<resj.length; i++){
        // if(!perioxesFilter.includes(resj[i].perioxi)){
        let id = resj[i].id;
    hideTableRow(id);
    // }
    if(nomosFilter.includes(resj[i].nomos)){
        let id = resj[i].id;
    showTableRow(id);
    hiden = 1;
    filterMarkersName1[i] = resj[i].last_name;
    filterMarkersCategory1[i] = resj[i].category;
    filterMarkerslat1[i] = resj[i].lat;
    filterMarkerslng1[i] = resj[i].lng;
            }
        }

    }else if (perioxesFilter == 0 && categoryFilter != 0 && nomosFilter == 0){
        for(let i=0; i<resj.length; i++){
        // if(!perioxesFilter.includes(resj[i].perioxi)){
        let id = resj[i].id;
    hideTableRow(id);
    // }
    if(categoryFilter.includes(resj[i].category)){
        let id = resj[i].id;
    showTableRow(id);
    hiden = 1;
    filterMarkersName1[i] = resj[i].last_name;
    filterMarkersCategory1[i] = resj[i].category;
    filterMarkerslat1[i] = resj[i].lat;
    filterMarkerslng1[i] = resj[i].lng;
            }
        }

    }else if (perioxesFilter == 0 && categoryFilter == 0 && nomosFilter == 0 && hiden==1){
        for(let i=0; i<resj.length; i++){
        // if(!perioxesFilter.includes(resj[i].perioxi)){
        let id = resj[i].id;
    showTableRow(id);
    hiden = 0;
            // }
        }

    }
    filterMarkersName.set(filterMarkersName1);
    filterMarkersCategory.set(filterMarkersCategory1);
    filterMarkerslat.set(filterMarkerslat1);
    filterMarkerslng.set(filterMarkerslng1);
}

</script>

    <!-- < Modal show = { $modal } /> -->
        <div class="container mx-auto px-4 pb-4">
            <MaterialApp>
                <Dialog class="pa-4" width={650} bind:active={showDialog}>
                    <svelte:component this={selected.component} />
                </Dialog>


                <!-- <div class="d-flex flex-column flex-sm-row"> -->
                    <Row>
                        <Col>
                            <Button class="primary-color" on:click={refreshClick}>Ανανέωση</Button>
                        </Col>
                        <Col>
                            <Button class="primary-color" on:click={() => { selected = options[3]; showDialog = true }}>Προσθήκη χρήστη</Button>
                        </Col>
                        <Col>
                            <Button class="primary-color" on:click={() => { selected = options[2]; showDialog = true }}>Προβολή Χάρτη</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Select chips multiple outlined items={uniquePerioxes} bind:value={perioxesFilter} on:change={hilander}>Περιοχή</Select>
                        </Col>
                        <Col>
                            <Select chips multiple outlined items={uniqueCategory} bind:value={categoryFilter} on:change={hilander}>Κατηγορία</Select>
                        </Col>
                        <Col>
                            <Select chips multiple outlined items={uniqueNomos} bind:value={nomosFilter} on:change={hilander}>Νομός</Select>
                        </Col>
                    </Row>
            </MaterialApp>
            <!-- </div> -->
<!-- < !--id="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl" -- > -->

    <div id="allUserShow">
        {#if resj==null}
        <div class="flex">
            <ProgressCircular
                size={70}
                width={7}
                indeterminate
                color="success"
            />
        </div>
        {:else}
        <div>
            <table class="w-full table-auto" id="usersdata">
                <thead>
                    <tr
                        class="bg-gray-200 text-gray-600 text-sm leading-normal"
                    >
                        <th class="py-3 px-2 text-left">Ονοματεπώνυμο</th>
                        <th class="py-3 px-2 text-left">Email</th>
                        <th class="py-3 px-2 text-center">Lot</th>
                        <th class="py-3 px-2 text-center">Ειδικότητα</th>
                        <th class="py-3 px-2 text-center">Email ver</th>
                        <th class="py-3 px-2 text-center">Admin approve</th>
                        <th class="py-3 px-2 text-center">Τιμοκ.</th>
                        <th class="py-3 px-2 text-center">Date</th>
                        <th class="py-3 px-6 text-center">Actions</th>
                    </tr>
                </thead>

                <tbody class="text-gray-600 text-sm font-light">
                    {#each resj as customer}
                    <tr
                        class="border-b border-gray-200 hover:bg-gray-100"
                        id={customer.id}
                    >
                        <td class="py-3 px-2 text-left">
                            <div class="flex items-center">
                                <div class="mr-1">
                                    {customer.first_name +
                                        " " +
                                        customer.last_name}
                                </div>
                                <!-- <span class="font-medium">React Project</span>-------------------- -->
                            </div>
                        </td>
                        <td class="py-3 px-2 text-left">
                            <div class="flex items-center">
                                <div class="mr-2">
                                    {customer.email}
                                </div>
                                <!-- <span>Eshal Rosas</span>--------------------------- -->
                            </div>
                        </td>
                        <td class="py-3 px-2 text-center">
                            <div class="flex items-center justify-center">
                                {customer.lots_show}
                            </div>
                        </td>
                        <td class="py-3 px-2 text-center">
                            <span
                                class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs"
                            >{customer.category}</span
                            >
                        </td>
                        <td class="py-3 px-2 text-center">
                            {#if customer.email_verification}
                            <span
                                class="bg-green-400 text-green-800 py-1 px-3 rounded-full text-xs"
                            >Yes</span
                            >
                            {:else}
                            <span
                            class="bg-red-400 text-red-800 py-1 px-3 rounded-full text-xs"
                            >No</span
                            >
                            {/if}
                        </td>
                        <td class="py-3 px-2 text-center">
                            {#if customer.admin_aproval}
                            <span
                                class="bg-green-400 text-green-800 py-1 px-3 rounded-full text-xs"
                            >Yes</span
                            >
                            {:else}
                            <span
                            class="bg-red-400 text-red-800 py-1 px-3 rounded-full text-xs"
                            >No</span
                            >
                            {/if}
                        </td>
                        <td class="py-3 px-2 text-center">
                            <span
                                class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs"
                            >{customer.catalog}</span
                            >
                        </td>
                        <td class="py-3 px-2 text-center flex-col">
                            <span
                                class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs"
                            >{customer.create_date}</span
                            >
                            <span
                                class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs"
                            >{customer.update_date}</span
                            >
                        </td>
                        <td class="py-3 px-6 text-center">
                            <div class="flex item-center justify-center">
                                <div
                                    class="w-4 mr-7 transform hover:text-purple-500 hover:scale-110"
                                >
                                    <!-- <a href="https://www.w3schools.com"> -->
                                        <Button
                                            size="x-small"
                                            class="indigo white-text"
                                            on:click={showPopup(customer.id)}
                                        >Edit</Button
                                        >

                                        <!-- </a> -->
                                </div>

                                <div
                                    class="w-4 mr-1 transform hover:text-purple-500 hover:scale-110"
                                >
                                    <Button
                                        size="x-small"
                                        class="red white-text"
                                        on:click={deleteRow(customer.id)}
                                    >Del.</Button
                                    >
                                </div>
                            </div>
                        </td>
                    </tr>
                    {/each}
                </tbody>
            </table>
        </div>
        <!-- <div>
            <div
                class="bg-white w-100 h-60 rounded shadow-md card sticky top-0"
            >
                <Map
                    {accessToken}
                    style="mapbox://styles/mapbox/outdoors-v11"
                    bind:this={mapComponent}
                    on:ready={onReady}
                    zoom="4"
                >
                    {#each users as customer_cord}
                    {#if customer_cord.lat != "0.0000000000000000" && customer_cord.lat != null}
                    <Marker

                        lat={customer_cord.lat}
                        lng={customer_cord.lng}
                        label={customer_cord.first_name +
                            " " +
                            customer_cord.last_name}
                        color="red"
                    />
                    {/if}
                        {/each}
                    </Map>
            </div>
        </div> -->
        {/if}
</div>
</div >