<script>
    import {
        Row,
        Col,
        TextField,
        Button,
        MaterialApp,
        Radio,
        Overlay, Tabs, Tab, Tooltip
    } from "svelte-materialify";
    import { FileDropzone } from "attractions";    
    import Editor from "../utils/Editor.svelte";
    import Upload from "../utils/Upload.svelte";
    // import Multiselect1 from "./Multiselect.svelte";
    // import MultiSelect from "svelte-multiselect";
    // import AutoComplete from "simple-svelte-autocomplete";
    import ProggressCircular from "../utils/ProggressCircular.svelte";
    // import {
    //     NotificationDisplay,
    //     notifier,
    // } from "@beyonk/svelte-notifications";
    import SelectComp from 'svelte-select';
    import { quill } from 'svelte-quill';

    export let gxid = null;
    export let idb = null;
    let p_json = null;
    let galaxy = false;
    let group = 1;
    let pdf=[];
    let json_product = [
        {
            id_product: null,
            code_product: null,
            id_galaxy: null,
            name_product: null,
            product_slug: null,
            category_product: null,
            image_product: null,
            second_images_product: null,
            docs_product: null,
            product_atribuutes: null,
            product_tags: null,
            product_brand: null,
            is_for_guests: null,
            multi_price: null,
            short_doc: null,
            step: 1,
            packageString:''
        },
    ];
    let html = "";
    // let selected = null;
    let selectedValues = [];
    let selectedBrands = null;
    // let selectedTags = [];
    let selectedValuesTags = [];
    // let tag_string_search = "";
    // let text = "";
    // let newBrand = "";
    let overlayActive = false;
    // let brand = null;
    let editor_idb = null;
    let productSlug = null;
    let shortDoc="";
    const rules = [(v) => v.length <= 50 || 'To πολύ 50 χαρακτήρες'];

    let rowUpload;
    let colUpload;

    $: if (gxid != null) {
        const cat_f = categories_fetch();         
        const brands_f_dum = brands_fetch();
        const tags_f_dum = tags_fetch();
        const fetch = product_fetch(gxid); 
    }

    $: if (idb != null) {
        const cat_f = categories_fetch();
        const brands_f_dum = brands_fetch();
        const tags_f_dum = tags_fetch();
        const fetch = product_fetch(idb);
    }

    async function product_post() {
        // console.log(selectedBrands)
        // console.log(selectedBrands.label)
        let rowUploadDone = await rowUpload.uploads_img();
        let colUploadDone = await colUpload.uploads_img();

        if (json_product[0].id_product == null) {
            const post_product = await fetch("/api/v1/shop/product/create", {
                method: "POST",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    code_product: json_product[0].code_product,
                    id_galaxy: json_product[0].id_galaxy,
                    name_product: json_product[0].name_product,
                    product_slug: json_product[0].name_product.replaceAll(' ','-').replaceAll('/','').replaceAll(' ','-'),
                    image_product: rowUploadDone,
                    second_images_product: colUploadDone,
                    docs_product: html,
                    product_tags: selectedValuesTags,
                    product_brand: selectedBrands.label?selectedBrands.label:null,
                    is_for_guests: group,
                    selectedValues,
                    step: json_product[0].step,
                    short_doc: content.html===''? shortDoc:content.html,
                    sale: (100-json_product[0].sale)/100,
                    start_of: json_product[0].start_of!=''?json_product[0].start_of:null,
                    end_of: json_product[0].end_of!=''?json_product[0].end_of:null,
                    packageString:json_product[0].packageString
                }),
            });
            if (post_product.ok) {
                overlayActive = false;
                let post_productJson = await post_product.json();
                if (post_productJson.id) {
                    json_product[0].id_product = post_productJson.id;
                    json_product = json_product;
                }
                // notifier.success(post_productJson.message, 10000);
            } else {
                overlayActive = false;
                // notifier.warning("Something went wrong...", 10000);
            }
        } else if (json_product[0].id_product != null) {
            const update_product = await fetch("/api/v1/shop/product/update", {
                method: "PATCH",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id_product: json_product[0].id_product,
                    code_product: json_product[0].code_product,
                    id_galaxy: json_product[0].id_galaxy,
                    name_product: json_product[0].name_product,
                    product_slug: json_product[0].product_slug,
                    image_product: rowUploadDone,
                    second_images_product: colUploadDone,
                    docs_product: html,
                    product_tags: selectedValuesTags,
                    product_brand: selectedBrands.label?selectedBrands.label:null,
                    is_for_guests: group,
                    selectedValues,
                    step: json_product[0].step,
                    short_doc: content.html===''? shortDoc:content.html,
                    sale: (100-json_product[0].sale)/100,
                    start_of: json_product[0].start_of!=''?json_product[0].start_of:null,
                    end_of: json_product[0].end_of!=''?json_product[0].end_of:null,
                    packageString:json_product[0].packageString
                }),
            });
            if (update_product.ok) {
                overlayActive = false;
                let post_productJson = await update_product.json();
                // if(post_productJson.id){
                //     json_product[0].id_product =post_productJson.id;
                //     json_product=json_product;
                // }
                // notifier.success(post_productJson.message, 10000);
            } else {
                overlayActive = false;
                // notifier.warning("Something went wrong...", 10000);
            }
        }
    }

    let url;
    if (idb != null) {
        url = "/api/v1/shop/product/site/";
        editor_idb = idb;
    } else if (gxid != null) {
        url = "/api/v1/shop/product/gx/";
        editor_idb = gxid;
    }

    let c_json = null;
    async function categories_fetch() {
        const c_fetch = await fetch(
            "/api/v1/shop/categories?category_level=products",
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    Accept: "application/json",
                },
            }
        );
        if (c_fetch.ok) {
            c_json = await c_fetch.json();
            return c_json;
        } else {
            throw new Error(c_fetch.message);
        }
    }
    
    let loadShortDoc = false;
    async function product_fetch(id) {
        try {            
            const p_fetch = await fetch(url + id, {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    Accept: "application/json",
                },
            });
            p_json = await p_fetch.json();
            if (p_json[0].gx_type == true) {
                galaxy = true;
                json_product = [
                    {
                        id_product: null,
                        code_product: p_json[0].gx_code,
                        id_galaxy: p_json[0].gx_id,
                        name_product: p_json[0].gx_desc,
                        product_slug: null,
                        category_product: null,
                        image_product: null,
                        second_images_product: null,
                        docs_product: null,
                        product_atribuutes: null,
                        product_tags: null,
                        product_brand: null,
                        is_for_guests: null,
                        multi_price: null,
                        short_doc: null,
                        step: 1,
                        packageString:''
                    },
                ];
                loadShortDoc=true;
            } else {                
                // console.log('ok3')
                galaxy = false;
                json_product = p_json;
                json_product[0].sale = ((1-json_product[0].sale)*100).toFixed();
                if(json_product[0].start_of!=null && json_product[0].start_of!=''){
                    json_product[0].start_of=json_product[0].start_of.slice(0, 19).replace('T', ' ');
                    // console.log('ok1')
                }
                if(json_product[0].end_of!=null && json_product[0].end_of!=''){
                    json_product[0].end_of=json_product[0].end_of.slice(0, 19).replace('T', ' ');
                    // console.log('ok2')
                }
                // console.log('ok3')
                group = p_json[0].is_for_guests;
                productSlug = p_json[0].product_slug;
                selectedValues=p_json[0].categories;
                selectedValuesTags=p_json[0].ptags;
                shortDoc=p_json[0].short_doc;
                loadShortDoc=true;
                if (p_json[0].docs_product != null) {                    
                    html = p_json[0].docs_product;
                }

                if (p_json[0].product_brand != null) {
                    selectedBrands={ label: p_json[0].product_brand,  value: p_json[0].product_brand};
                    // console.log(selectedBrands);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    let p_brand = null;
    async function brands_fetch() {
        const b_fetch = await fetch("/api/v1/shop/product/brands/hi", {
            method: "GET",
            credentials: "include",
            headers: {
                Authorization: "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                Accept: "application/json",
            },
        });
        if (b_fetch.ok) {
            p_brand = await b_fetch.json();
            // console.log(p_brand);
            return p_brand;
        } else {
            throw new Error(b_fetch.message);
        }
    }



    let p_tags = [];
    async function tags_fetch(filterText) {
        p_tags = [];
        // console.log("|" + keyword + "|");
        if (filterText !== undefined) {
            const t_fetch = await fetch(
                "/api/v1/shop/product/una/search_tag/a/" +
                    filterText,
                {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        Authorization:
                            "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                        Accept: "application/json",
                    },
                }
            );
            if (t_fetch.ok) {
                p_tags = await t_fetch.json();
                return p_tags;
            } else {
                return p_tags;
                throw new Error(t_fetch.message);
            }
        }
    }

    $: if (json_product[0].name_product != null) {
        productSlug = json_product[0].name_product.replaceAll(" ", "-");
        productSlug = json_product[0].name_product.replaceAll("/", "");
    }

    let options = { placeholder: "Σύντομη περιγραφή...",   
            modules: {
                toolbar: [
                    [{ header: [1, 2, 3, false] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'align': [] }],
                    [{ 'font': [] }],
                    ["link"],
                    ['clean']  
                ]
            },

    }
	
	let content = { html: '', text: '' };

    // $: shortDoc=content.html;
</script>

<svelte:head>
	<link href="//cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
</svelte:head>

<!-- Product image -->
<div>
    <!-- <NotificationDisplay /> -->
    {#if json_product.length}
        <Overlay absolute bind:active={overlayActive}>
            <ProggressCircular />
        </Overlay>
        <!-- <Container> -->
        <Row>
            <Col cols={12} md={9}>
                <Upload
                    row="true"
                    imageExist={json_product[0].image_product}
                    bind:this={rowUpload}
                    multipleImage="false"
                />

                <!-- Product info -->

                <div class="m-6">
                    <MaterialApp>
                        <Row>
                            <Col cols={3}>
                                <TextField
                                    disabled
                                    outlined
                                    value={json_product[0].id_product}
                                >
                                    Id
                                </TextField>
                            </Col>
                            <Col cols={3}>
                                <TextField
                                    outlined
                                    bind:value={json_product[0].code_product}
                                >
                                    Galaxy Code
                                </TextField>
                            </Col>
                            <Col cols={6}>
                                <TextField
                                    outlined
                                    bind:value={json_product[0].id_galaxy}
                                >
                                    Galaxy Id
                                </TextField>
                            </Col>
                        </Row>
                        <TextField
                            bind:value={json_product[0].name_product}
                            filled
                            rules={[(v) => !!v || "Required"]}
                            hint="Slug: {json_product[0].product_slug}"
                            >Όνομα προιόντος*</TextField
                        >
                        <!-- <TextField bind:value={shortDoc} filled {rules} on:keypress={()=>{if(shortDoc.length > 100){shortDoc = shortDoc.substring(0, 100);}}}
                            >Σύντομη περιγραφή</TextField
                        > -->
                        {#if loadShortDoc}
                        <div class="editor" use:quill={options} on:text-change={e => content = e.detail}>
                            {@html shortDoc}
                        </div>
                        {/if}
                        <!-- <Select filled items={roles} value="">Αποδεκτές ειδικότητες</Select> -->
                        <Row>
                            <Col cols={9}>
                                <div class="my-4">
                                    <div
                                        class="d-flex justify-space-around rounded-md bg-gray-100 h-16"
                                    >
                                        <Radio bind:group value={0} color="red"
                                            >Είναι μόνο για πελάτες</Radio
                                        >
                                        <Radio
                                            bind:group
                                            value={1}
                                            color="secondary"
                                            >Είναι και για επισκέπτες</Radio
                                        >
                                    </div>
                                </div>
                            </Col>
                            <Col cols={3}>
                                <div class="my-4">
                                    <TextField
                                        outlined
                                        bind:value={json_product[0].step}
                                        type="number"
                                    >
                                        <div slot="append">τμχ.</div>
                                        Συσκευασία:
                                    </TextField>
                                </div>
                            </Col>
                        </Row>
                        <label for="packDesk">Περιγραφή συσκευασίας</label>
                        <input id="packDesk" class="bg-gray-100 p-1 w-full" bind:value={json_product[0].packageString}/>
                    </MaterialApp>
                </div>
                <div class="mx-6 max-w-5xl max-h-96">
                    <Editor bind:value={html} />
                </div>
                <div class="my-24" />
            </Col>
            <Col cols={12} md={3} class="px-0" style="padding-left: 0">
                <div
                    class="mb-4 max-w-sm rounded-lg border-2 border-teal-700 p-2 themed_cat"
                >
                    <strong>Κατηγορίες προϊόντος</strong>
                    {#if c_json != null }
                        <SelectComp items={c_json} isMulti={true} bind:value={selectedValues} placeholder='Κατηγορία'/>

                    {:else}
                        <ProggressCircular />
                    {/if}
                </div>
                <div
                    class="mb-4 max-w-sm rounded-lg border-2 border-teal-700 p-2 themed_brand"
                >
                    <strong>Εταιρία προϊόντος</strong>
                    {#if p_brand != null}
                        <SelectComp items={p_brand} isMulti={false} bind:value={selectedBrands} isCreatable={true} placeholder='Brand'/>                        
                    {:else}
                        <ProggressCircular />
                    {/if}
                </div>
                <div
                    class="mb-4 max-w-sm rounded-lg border-2 border-teal-700 p-2"
                >
                    <strong>Ετικέτες προϊόντος</strong>
                    {#if p_tags != null}
                        <div class="flex-grow themed_tag">
                            <SelectComp loadOptions={tags_fetch} isMulti={true} bind:value={selectedValuesTags} isCreatable={true} placeholder='Tags'/>   
                        </div>
                    {:else}
                        <ProggressCircular />
                    {/if}
                </div>
                <div
                    class="mb-4 max-w-sm rounded-lg border-2 border-teal-700 p-2"
                >
                    <strong>Επιπλέον εικόνες</strong>
                    <Upload
                        col="true"
                        multipleImage="true"
                        imagesExist={json_product[0].second_images_product}
                        bind:this={colUpload}
                    />
                </div>
                <div class="mb-4 max-w-sm rounded-lg border-2 border-teal-700 p-2">
                    <strong>Έκπτωση</strong>
                    <div>
                        <input type="number" bind:value={json_product[0].sale} class="bg-blue-100 rounded-md h-10 text-xl font-bold p-2 w-24"><span class="text-2xl font-bold text-blue-600 mx-2">%</span>
                        <div class="">
                            <div class="my-2 w-full">
                                <label for="start_of">Start</label>
                                <input id="start_of" bind:value={json_product[0].start_of} class="bg-green-100 rounded-md h-10 p-1 w-full text-lg font-semibold focus:outline-none border-2 border-white focus:border-green-500" type="datetime-local">
                            </div>
                            <div class="my-2 w-full">
                                <label for="end_of">End</label>
                                <input id="end_of" bind:value={json_product[0].end_of} class="bg-red-100 rounded-md h-10 p-1 w-full text-lg font-semibold focus:outline-none border-2 border-white focus:border-red-500" type="datetime-local">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="h-36 my-2 px-4">
                    <FileDropzone
                        accept=".pdf"
                        max={3}
                        bind:files={pdf}
                    />
                </div> -->
            </Col>
        </Row>
        {#if selectedBrands!=null && selectedBrands.label}
        <Button
            class="red white-text"
            size="x-large"
            style="position: absolute; bottom: 0px; right: 0px; width:290px;"
            on:click={product_post}
            on:click={() => {
                overlayActive = true;
            }}>Αποθήκευση</Button
        >
        {:else}
        <div class="p-1 bg-red-500 text-white rounded" style="position: absolute; bottom: 0px; right: 0px; width:290px;">
            Brand required
        </div>
        {/if}
        <!-- </Container> -->
    {:else}
        <ProggressCircular />
    {/if}
</div>

<style>	
	/* 	
			CSS variables can be used to control theming.
			https://github.com/rob-balfre/svelte-select/blob/master/docs/theming_variables.md
	*/
	
	.themed_cat {
		--border: 3px solid rgb(255, 81, 0);
		--borderRadius: 10px;
		--placeholderColor: rgb(255, 81, 0);
	}
    .themed_brand {
		--border: 3px solid blue;
		--borderRadius: 10px;
		--placeholderColor: blue;
	}
    .themed_tag {
		--border: 3px solid rgb(60, 255, 0);
		--borderRadius: 10px;
		--placeholderColor: rgb(0, 255, 76);
	}
	
	/* .icon {
		--selectedItemPadding: 0 10px 0 8px;
		--inputPadding: 0 10px 0 40px;
	} */
</style>