<script>
    import { user_logon, user_role } from "./comp/utils/stores.js";
    // import { Button, Alert, Icon, Dialog, MaterialApp } from "svelte-materialify";
    // import { mdiCheck, mdiThumbUp, mdiAlert } from "@mdi/js";
    // import { scale } from "svelte/transition";

    // import Afm from "./components/Afm.svelte";
    // import Modal, { getModal } from "./admin_med/Modal.svelte";

    export const title = "Σύνδεση";


    let email = "";
    let password = "";



    let alert = false;

    let message = null;

    async function handleSubmit() {
        const res = await fetch("/api/v1/users/login", {
            method: "POST",
            credentials: "include",
            mode: "same-origin",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ email: email, password: password }),
        });
        const json = await res.json();


        if (res.ok) {
            // alert = true;
            // redalert = false;
            user_logon.set(true);
            user_role.set(json.role);
            // return alert;
        } else {
            // redalert = true;
            // alert = false;
            message = json.message;
            // return json;
        }
    }

</script>



<div class="my-20 flex justify-center ">
    <!-- login -->
    <!-- <div class=""> -->
        <!-- class="flex items-center justify-center" -->
        <div class="w-[270px] rounded-3xl border-2 bg-white px-6 py-3">
            <div class="mb-3 flex flex-col items-center justify-center">
                <img
                    src="https://medil.gr/medil-black-1.gif"
                    alt=""
                    class="mb-1 h-12"
                />
                <h2 class="text-2xl font-bold">Admins Login</h2>
            </div>
            {#if !$user_logon}
                <form on:submit|preventDefault={() => handleSubmit()}>
                    <!-- username -->
                    <div class="my-2 flex flex-col">
                        {#if message}
                            <div
                                class="flex items-center justify-between text-sm text-red-400"
                            >
                                <span>
                                    <b>Error: </b>
                                    {message}
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                        {/if}
                        <label class="text-xs text-gray-400">Email</label>
                        <input
                            class="mt-1 rounded border px-3 py-1 bg-blue-100"
                            required
                            type="text"
                            bind:value={email}
                        />
                    </div>
                    <div class="my-2 flex flex-col">
                        <label class="text-xs text-gray-400">Password</label>
                        <input
                            class="mt-1 rounded border px-3 py-1 bg-blue-100"
                            required
                            type="password"
                            bind:value={password}
                        />
                    </div>
                    <div class="mt-3 flex flex-col items-center justify-center">
                        <button
                            type="submit"
                            class="my-3 w-full rounded bg-blue-600 py-1 !text-blue-100"
                            >Σύνδεση</button
                        >
                        <!-- <p class="text-xs text-gray-500">
                      Forgot password ?
                      <a href="#" class="font-bold text-gray-700">Click here</a>
                      to reset your password.
                    </p> -->
                    </div>
                </form>
                <a href="/lost-password" class="no-underline">
                    <button
                        class="w-full rounded-md py-2.5 px-5 text-sm text-red-600 hover:bg-red-100 focus:outline-none"
                        >Ξέχασα τον κωδικό μου</button
                    >
                </a>
                <a href="/signup" class="no-underline">
                    <button
                        class="w-full rounded-md py-2.5 px-5 text-sm text-green-600 hover:bg-green-100 focus:outline-none"
                        >Δεν έχω λογαριασμό</button
                    >
                </a>
            {:else}
                <div
                    class="flex flex-col my-10 items-center justify-between text-base text-green-400"
                >
                    <span>
                        <!-- <b>Error: </b> -->
                        Είσαστε συνδεδεμένοι!
                    </span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        class="h-8 w-8 my-4"
                        stroke="currentColor"
                        viewBox="0 0 50 50"
                        style=" fill:#000000;"
                    >
                        <path
                            d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 34.988281 14.988281 A 1.0001 1.0001 0 0 0 34.171875 15.439453 L 23.970703 30.476562 L 16.679688 23.710938 A 1.0001 1.0001 0 1 0 15.320312 25.177734 L 24.316406 33.525391 L 35.828125 16.560547 A 1.0001 1.0001 0 0 0 34.988281 14.988281 z"
                        />
                    </svg>
                </div>
            {/if}
        </div>
        <!-- <div class="relative  top-14 lg:left-[120px] xl:left-44 2xl:left-44">
            <a rel="external" href="https://admin.medil.gr/site_images/ΜΜΕ_WEB ΕΛΛΗΝΙΚΑ.pdf">
                <img
                    src="https://admin.medil.gr/site_images/espa-epidotisi-eshop-2021.png"
                    alt="espa"
                    style="background-size: contain;"
                    class="h-20 w-96 object-contain rounded-md"
                />
            </a>
        </div> -->
    <!-- </div> -->
</div>
