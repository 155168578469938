// routes.js
import Home from './routes/Home.svelte'
import Users from './routes/Users.svelte'
import Admin_categories from './routes/DevCategories.svelte'
import Admin_shop from './routes/DevShop.svelte'
import Admin_page from './routes/DevPage.svelte'
import Admin_polisis from './routes/Polisis.svelte'
import Admin_products from './routes/DevProducts.svelte'
import PageNotExists from './routes/PageNotExists.svelte'
import Settings from './routes/Settings.svelte'
import ProductTeams from './routes/ProductTeams.svelte'
import ProductTeamsCreate from './routes/comp/teams/CreateTeam.svelte'
import EditTeam from './routes/comp/teams/EditTeam.svelte'

export default [
    {
		path: '/',
		component: Home
	},
    {
		path: '/settings',
		component: Settings
	},
	{
		path: '/productsaggregations',
		component: ProductTeams
	},
	{
		path: '/productsaggregations/:id/editteam',
		component: EditTeam
	},
	{
		path: '/productsaggregations/create',
		component: ProductTeamsCreate
	},
	{
		path: `/admin_med/shop/:id/:slug`,
		component: Admin_categories,
		// auth: true
	},
    {
		path: `/tsolakidisapothema`,
		component: Admin_polisis,
		// auth: true
	},
	{
		path: '/shop',
		component: Admin_shop,
		// auth: true
	},
    {
		path: '/admin_med',
		component: Admin_page,
		// auth: true
	},
    {
		path: '/admin_med/products/:cid/:cat_slug',
		component: Admin_products
    },
    {
		path: '/users',
		component: Users
	},
	{
		path: '*',
		component: PageNotExists
	}
]