<script>
    let resj=[];
    async function getEidikotites(){
        // console.log("hello")
        const res = await fetch('/api/v1/settings/fr/2');
        resj = await res.json();
        // console.log(resj)

        return resj;
    }

    async function saveToDb(){
        await fetch(`/api/v1/settings/bu/2`,{
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data:resj
            })
        });
    }
</script>

<div class="my-12">
    <div class="flex">
        <div class="text-xl text-blue-500">Ειδικότητες</div>
        <button
            on:click={saveToDb} 
            class="bg-green-600 rounded-md text-white text-xl p-1 mx-2 hover:bg-green-700 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out">
            Αποθήκευση
        </button>
    </div>
    {#await getEidikotites() then items}
        <div class="flex flex-wrap">
            {#each resj as item}
                <input type="text" class="bg-blue-100 rounded-lg w-auto h-12 mx-4" bind:value={item}/>
            {/each}
        </div>
    {/await}
</div>