<script>
    import {
        DataTable,
        DataTableHead,
        DataTableRow,
        DataTableCell,
        DataTableBody,
        MaterialApp,
        TextField,
        Row, Col
    } from "svelte-materialify";
    import ProggressCircular from "../utils/ProggressCircular.svelte";

    let infos = [];
    export let id = null;
    let call_function;
    $: console.log(id);
    $: if (id != null) {
        call_function = fetch_product_info(id);
    }

    async function fetch_product_info(produc_id) {
        const res = await fetch(
            `/api/v1/shop/product/una/product_galaxy_info/gx/` + produc_id,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    Accept: "application/json",
                },
            }
        );
        infos = await res.json();
        console.log(infos);
    }
</script>

{#if infos.length}
    <MaterialApp>
        <Row>
            <Col cols={3}>
                <TextField  outlined value={infos[0].id}>
                    Id
                </TextField>
            </Col>
            <Col cols={3}>
                <TextField outlined bind:value={infos[0].gx_code}>
                    Galaxy Code
                </TextField>
            </Col>
            <Col cols={6}>
                <TextField outlined bind:value={infos[0].gx_id}>
                    Galaxy Id
                </TextField>
            </Col>
        </Row>
        <Row>
            <Col cols={6}>
                <TextField  outlined value={infos[0].gx_desc}>
                    Περιγραφή προϊόντος
                </TextField>
            </Col>
            <Col cols={3}>
                <TextField outlined bind:value={infos[0].vat_desc}>
                    ΦΠΑ
                </TextField>
            </Col>
            <Col cols={3}>
                <TextField outlined bind:value={infos[0].balance}>
                    Απόθεμα
                </TextField>
            </Col>
        </Row>
        <Row>
            <Col cols={4}>
                <TextField  outlined value={infos[0].price1}>
                    Τιμή αγοράς
                </TextField>
            </Col>
            <Col cols={4}>
                <TextField outlined bind:value={infos[0].price2}>
                    Τιμή χονδρικής
                </TextField>
            </Col>
            <Col cols={4}>
                <TextField outlined bind:value={infos[0].price3}>
                    Τιμή λιανικής
                </TextField>
            </Col>
        </Row>
        <Row>
        <DataTable>
            <DataTableHead>
                <DataTableRow>
                    <DataTableCell>Κωδ. παρτίδας</DataTableCell>
                    <DataTableCell >Ποσότητα</DataTableCell>
                    <DataTableCell >Ημ. λήξης</DataTableCell>
                    <!-- <DataTableCell>Κωδ. προϊόντος</DataTableCell> -->
                </DataTableRow>
            </DataTableHead>
            <DataTableBody>
                {#each infos[1] as lots}
                <DataTableRow>
                    <DataTableCell>{lots.code}</DataTableCell>
                    <DataTableCell>{lots.balance}</DataTableCell>
                    <DataTableCell>{lots.exp_date}</DataTableCell>
                    <!-- <DataTableCell>{lots.code}</DataTableCell> -->
                </DataTableRow>
                {/each}

            </DataTableBody>
        </DataTable>
    </Row>
    </MaterialApp>
{:else}
    <ProggressCircular />
{/if}
