<script>
    import { count, param, customerId, googleApiKey } from "../utils/stores.js";
    import Popup from "./Popup.svelte";
    import UserMap from "./UserMap.svelte";
    import UserSettings from "./UserSettings.svelte";
    import Addresses from "./Addresses.svelte";
    import { Tabs, Tab, MaterialApp, TabContent, Overlay, Button} from "svelte-materialify";
    import {onMount} from 'svelte';

    let resj=null;
    let activeOverlay=false;
    let fetchResj =null;
    let resNO = null;
    let resPostal=null;
    onMount(async () => {
        const res = await fetch(
            "/api/v1/users/id/" + $customerId,
            {
                method: "GET",
                credentials: 'include',
                headers: {
                    Authorization: $param + $count,
                    Accept: "application/json",
                },
            }
        );
        resj = await res.json();
        fetchResj = resj.address;
        resNO=resj.numbe;
        resPostal=resj.postal;
        console.log(resj);

        if (res.ok) {
            return resj;
        } else {
            throw new Error(result);
        }
    });
    let resjCord=null;
    async function fetchCord (resj){
		if (fetchResj != resj.address || resNO != resj.numbe || resPostal != resj.postal){
            try{
                const resCordinates = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address="+resj.address + "+"+resj.numbe+"," +resj.postal +"&key="+googleApiKey);
                resjCord = await resCordinates.json();
            }catch(error){
                console.log(error);
                updateUser(resj, null, null, "ZERO_RESULTS");
            }
		    updateUser(resj, resjCord.results[0].geometry.location.lat, resjCord.results[0].geometry.location.lng, resjCord.status);
        }else{
            updateUser(resj, resj.lat, resj.lng, resj.status);
        }

	}
	
	async function updateUser(resj, lat, lng, status){
        activeOverlay=true;
		const res = await fetch("/api/v1/users/id/" + $customerId, {
      method: "PATCH",
      credentials: "include",
      headers: {
		Authorization: $param + "",
        "Content-Type": "application/json",        
        Accept: "application/json"
      },
      body: JSON.stringify({ "username": resj.username,
		"first_name": resj.first_name,
		"last_name": resj.last_name,
		"email": resj.email,
		"role": resj.role,
		"postal": resj.postal,
		"address": resj.address,
		"numbe": resj.numbe,
		"country": resj.country,
		"tel1": resj.tel1,
		"tel2": resj.tel2,
		"vat": resj.vat,
		"doy": resj.doy,
		"perioxi": resj.perioxi,
		"category": resj.category[0].value,
		"nomos": resj.nomos,
		"type": resj.type,
		"price_tag": resj.price_tag,		
		"lat": lat,
		"lng": lng,
		"cord_status":status,
        "email_verification": resj.email_verification,
        "admin_aproval": resj.admin_aproval,
        "catalog":resj.catalog,
        "lots_no":resj.lots_no,
        "lots_show":resj.lots_show,
        "payment_method": resj.payment_method,
        "first_sale": resj.first_sale,
        "first_sale_complete": resj.first_sale_complete,
        "sales":resj.sales
	})
    });	
	const json = await res.json();
    if(res.ok){
        activeOverlay=false;
    }
}

</script>

{#if resj!=null && resj!=undefined}
<MaterialApp>
    <Overlay absolute bind:active={activeOverlay} /> 
    <Tabs grow class="green-text">
        <div slot="tabs">
            <Tab>Στοιχεια</Tab>
            <Tab>Ρυθμισεις</Tab>
            <Tab>Διευθυνσεις</Tab>
            <Tab>Χαρτης</Tab>
        </div>
        <TabContent>
            <Popup bind:resj={resj}/>
        </TabContent>
        <TabContent>
            <UserSettings bind:resj={resj}/>
        </TabContent>
        <TabContent>
            <Addresses bind:resj={resj.addresses} info={{last_name: resj.last_name, first_name:resj.first_name}}/>
        </TabContent>
        <TabContent>
            <UserMap resj={resj}/>
        </TabContent>
    </Tabs>    
    <Button type="submit"
        block
        class="blue white-text"
        on:click={fetchCord(resj)}
        >Αποθηκευση
    </Button>
</MaterialApp>
{/if}