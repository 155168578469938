<script>
    import { Snackbar, Button, MaterialApp } from 'svelte-materialify';
  
    let snackbar = false;
  </script>
  
  <MaterialApp>
  <div class="text-center pa-3">
    <Button
      on:click={() => {
        snackbar = true;
      }}>
      Click
    </Button>
  </div>
  
  <Snackbar class="flex-column" bind:active={snackbar} bottom center timeout={3000}>
    Here are some actions
    <div class="mt-1">
      <Button text class="success-text">Accept</Button>
      <Button
        class="red-text"
        text
        on:click={() => {
          snackbar = false;
        }}>
        Dismiss
      </Button>
    </div>
  </Snackbar>
  </MaterialApp>