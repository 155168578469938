<script>
    import {
        TextField,
        Button,
        MaterialApp,
        Radio,
        Overlay
    } from "svelte-materialify";
    // import Carousel from "@beyonk/svelte-carousel";
    // import { ChevronLeftIcon, ChevronRightIcon } from "svelte-feather-icons";
    import { getModal } from "../utils/Modal.svelte";
    import ProggressCircular from "../utils/ProggressCircular.svelte";
    import Upload from '../utils/Upload.svelte';
    import CatSelect from './CategorySelect.svelte';
    import greekUtils from 'greek-utils';

    let overlayActive = false;
    let uploadImage;
    let guests;

    // $: console.log(category_parents);

    export let pid;
    let category;
    let category_name= null, 
        category_slug= null, 
        category_image= null, 
        category_parents= [], 
        category_level= null, 
        cat_accepted_roles= null,
        tags= null;

    $: category_slug = greekUtils.toGreeklish(category_name)
    
    // let categories = null;
    // let avatar, fileinput;

    const roles = ["Roloi erxontai!", "Υποκατηγορίες", "Προϊόντα"];
    let group;

    async function create_cat() {
        // if(img!=null){
        //     const formData = new FormData();
        //     formData.append('myFile', img);

        //     console.log(category);
            
        //     const post_image = await fetch("/api/v1/shop/imageup/cats", {
        //         method: "POST",
        //         credentials: "include",
        //         headers: {
        //             Authorization: "8UKDHJ_medil_by_andre_koukouroukou 56526658741"
        //         },
        //         body: formData
        //     });

        //     image_path = await post_image.json();
        // }else{
        //     image_path = [{path: category.category_image}];
        // }

        let upDone = await uploadImage.uploads_img();

        const res_category_create = await fetch(
            "/api/v1/shop/categories/create",
            {
                method: "POST",
                credentials: "include",
                headers: {
                    Authorization: "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                        "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    category_name: category_name,
                    category_slug: category_slug,
                    category_image: upDone,
                    cat_is_for_guest: guests,
                    category_level: group,
                    cat_accepted_roles: null,
                    tags: tags,
                    parents:category_parents
                }),
            }
        );

        let done= await res_category_create.json();

        // const add_to_parrent_cat = await fetch(
        //     "/api/v1/shop/categories/" + pid,
        //     {
        //         method: "PATCH",
        //         credentials: "include",
        //         headers: {
        //             Authorization:
        //                 "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
        //                 "Content-Type": "application/json"
        //         },
        //         body: JSON.stringify({
        //             category_child_order: ids + ',' + new_cat_id.id
        //         }),
        //     }
        // );

        // let done=await add_to_parrent_cat.json();
        console.log(done);
        if(done.id){
            overlayActive=false;
            console.log('now ok');
            getModal('create').close(1);
        }
    }
</script>

<Overlay absolute bind:active={overlayActive}>
    <ProggressCircular />
</Overlay>

    <Upload row={true}
            type='cats'
            bind:this={uploadImage}/>

    <div class="m-8">
        <MaterialApp>
            <TextField bind:value={category_name} filled
                >Όνομα κατηγορίας*</TextField
            >
            <TextField bind:value={category_slug} filled
                >Slug (αγγλικά μικρά)</TextField
            >
            <TextField bind:value={tags} filled
            >Ετικέτες κατηγορίας (χωρισμένες με κόμματα)</TextField
        >
            <!-- <Select filled items={roles} value="">Αποδεκτές ειδικότητες</Select> -->
            {#if group != 'top'}
            <div class="rounded border-2 border-cyan-600 border-solid bg-cyan-50 my-4 px-2 max-w-2xl">
                <strong>Κατηγορία γονέα</strong>
                <CatSelect pid={pid} bind:selectedValues={category_parents}/>
            </div>
            <!-- {:else}
            <div class="rounded border-2 border-cyan-600 border-solid bg-cyan-50 my-4 px-2 h-16">
            </div> -->
            {/if}

            <div class="my-4">
                <div
                    class="d-flex justify-space-around rounded-md bg-gray-100 h-16"
                >
                    <Radio bind:group={guests} value={0} color="red"
                        >Είναι μόνο για πελάτες</Radio
                    >
                    <Radio
                        bind:group={guests}
                        value={1}
                        color="secondary"
                        >Είναι και για επισκέπτες</Radio
                    >
                </div>
            </div>
            
            <div class="rounded border-2 border-cyan-600 border-solid mt-4 mb-10 p-4 ">
                Τύπος κατηγορίας:
                <div class="d-flex justify-space-around">
                    <Radio bind:group value={'top'} color="red"
                        >Αρχική κατηγορία</Radio
                    >
                    <Radio bind:group value={'sub'} color="secondary"
                        >Υποκατηγορία</Radio
                    >
                    <Radio bind:group value={'products'} color="success"
                        >Προϊόντα</Radio
                    >
                </div>
            </div>
        </MaterialApp>
    </div>
    <Button
        block
        class="red white-text"
        style="position: absolute; bottom:0px; right: 0px;" on:click={create_cat} on:click={()=> overlayActive=true}>Αποθήκευση</Button>
    <!-- <MaterialApp /> -->
