<script>
    import { onMount } from 'svelte'
    import { tick } from 'svelte';
    import ProggressCircular from '../utils/ProggressCircular.svelte';
    import PopupCreateUser from "./PopupCreateUser.svelte";
    import { Dialog, MaterialApp } from "svelte-materialify";
    import router from "page";
    
    let f1=null, f2=null, f3=null, f4=null, f5=null, f6=null, f7=null, f8=null, f9=null;
    let inputsNo=[f1=null, f2=null, f3=null, f4=null, f5=null, f6=null, f7=null, f8=null, f9=null];
    export let closeAfmModal=true;
    
    let otp=true;
    let afm='';
    export let afmJson;
    
    let activeNewUserFields=false;
    // $: console.log("field "+ activeNewUserFields);
    // let v=[v1, v2, v3, v4, v5, v6];
    
    // onMount(() => inputsNo[0].focus())
    async function autofocus() {
            await tick();
            inputsNo[0].focus();
        }
    
    function withoutAfm(){
      afmJson= JSON.stringify({ afm: '',
                commerTitle: '',
                doyDescr: "",
                firmActDescr: "",
                onomasia: "",
                postalAddress: "",
                postalAddressNo: "",
                postalAreaDescription: "",
                postalZipCode: ""});
      // closeAfmModal=false; 
      activeNewUserFields=true;  
    }
    
    async function fetchAfm(){
        const afmFetch = await fetch("/api/v1/users/gafm/"+afm);
        if(afmFetch.ok){
          afmJson=null;        
            try{
                afmJson = await afmFetch.json();
                console.log(afmJson); 
                // otp=true;
                // closeAfmModal=false; 
                activeNewUserFields=true;
            }catch{
                otp=true;
                // closeAfmModal=false;  
            }   
        }
        else{
    
        }
    }
        
    function oninput(e, f){
        // console.log("f: "+f)
        if((e.keyCode === 8 && f!=0) || (e.keyCode === 37 && f!=0)) {
                inputsNo[f-1].focus();
                inputsNo[f].value=null;
            } else if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //|| (e.keyCode >= 65 && e.keyCode <= 90)
                inputsNo[f].value=e.key;
                if(f+1<inputsNo.length){
                    inputsNo[f+1].value=' ';            
                    inputsNo[f+1].focus();
                }
                else{
                    afm='';
                    for(let i=0; i<inputsNo.length; i++){
                        afm=afm+inputsNo[i].value;
                    }
                    otp=false;
                    fetchAfm(afm);
                }
            }
    }
    </script>
    
    
    <!-- <svelte:window on:keydown={(e) => handleKeydown(e)}/> -->
    
        <div class="container mx-auto">
            <div class="mx-auto max-w-sm md:max-w-lg">
              <div class="w-full">
                <div class="h-64 rounded bg-white py-3 text-center">
                  <h1 class="text-2xl font-bold">Αυτόματη συμπλήρωση</h1>
                  <div class="mt-4 flex flex-col">
                    <span>Εισάγεται το επαγγελματικό Α.Φ.Μ. σας για αυτόματη συμπλήρωση των στοιχείων σας.</span>
                    <!-- <span class="font-bold"></span> -->
                  </div>
                  {#if otp}
                    <div id="otp" class="mt-5 flex flex-row justify-center px-2 text-center">
                        {#each inputsNo as digit, i}
                        <input bind:this={digit} on:keydown={e=>oninput(e,i)} class="form-control m-1 h-10 w-10 rounded border text-center light-blue lighten-5" type="text" maxlength="1" use:autofocus />
                        {/each}
                    </div>
                  {:else}
                    <ProggressCircular/>               
                  {/if}      
    
          
                  <div class="mt-5 flex justify-center text-center">
                    <button type="button" class="focus:outline-none blue-text text-sm py-2.5 px-5 rounded-md hover:bg-blue-100" on:click={withoutAfm}>Συνέχεια χωρίς αυτόματη συμπλήρωση</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <MaterialApp>
            <Dialog class="pa-4" persistent width={800} bind:active={activeNewUserFields} on:outroend={() => router.redirect("/")}>
              <PopupCreateUser bind:afmData={afmJson} bind:afmModal={closeAfmModal} bind:closeModalCreateUser={activeNewUserFields}></PopupCreateUser>
            </Dialog>
          </MaterialApp>