<script>
    import router from "page";
    import routes from "./routes";
    import {onMount} from 'svelte';
        import {
          user_logon,
          user_role,
          fin_cart_products,
      } from "./routes/comp/utils/stores.js";
  
    let page = null;
    let params = {};
    let user = true;
  
    let json = null;
    let role = "";
  
    onMount(async () => {
          const res = await fetch(`/api/v1/users/status`);
          json = await res.json();
          user_logon.set(json.connected);
          user_role.set(json.role);
          role = json.role;
          console.log($user_role + $user_logon)
      });
  
    routes.forEach(route => {
      router(
        route.path,
        (ctx, next) => {
          params = { ...ctx.params };
          next();
        },
        () => {
          if (route.auth && !user) {
            router.redirect("/");
          } else {
            page = route.component;
          }
        }
      );
    });
  
    router.start();
  </script>
  
  <svelte:head>
  </svelte:head>
  
  {#if $user_logon && $user_role == "Admin"}
  <nav class="m-4 flex justify-center">
      <a href="/" class="mx-2 font-medium text-xl text-blue-600">Αρχική</a>
      <a href="/admin_med/shop/top/top-categories" class="mx-2 font-medium text-xl text-blue-600">Προιόντα</a>
      <a href="/users" class="mx-2 font-medium text-xl text-blue-600">Χρήστες</a>
      <a href="/settings" class="mx-2 font-medium text-xl text-blue-600">Ρυθμίσεις</a>
      <a href="/productsaggregations" class="mx-2 font-medium text-xl text-blue-600">Ομάδες προιόντων</a>
  </nav>
  {/if}
  
  <main>
    {#if json != null}
    <svelte:component this={page} {params} />
    {/if}
  </main>
  
  <style global>
      @tailwind base;
      @tailwind components;
      @tailwind utilities;
  </style>