<script>
    // import { Dialog } from "svelte-materialify";
    // import NewAddress from "./NewAddress.svelte";
    // import { onMount } from "svelte";
    export let resj = [];
    let radioAddress;
    export let info = null;
    // let activeModalMap = false;
    // let isEdit = false;
    // let data = null;
    // let place;
    // $: console.log(place);
    // onMount(async () => {
    //     calls();
    // });

    // $: if (!activeModalMap) {
    //     calls();
    //     isEdit = false;
    //     data = null;
    // }

    let oldRadioAddress = null;

    // async function changeDefault(old, newid) {
    //     await fetch(
    //         `https://medil.gr/api/v1/users/editAddress/` + newid + `?oldid=` + old,
    //         {
    //             method: "PATCH",
    //         }
    //     );
    //     oldRadioAddress = newid;
    // }

    // async function calls() {
        // const res = await fetch(`https://medil.gr/api/v1/users/getAddress`);
        // resj = await res.json();
        radioAddress = resj[0]?.id;
        // const whoami = await fetch(`https://medil.gr/api/v1/users/whoami`);
        // info = await whoami.json();
        for (let i = 0; i < resj.length; i++) {
            if (resj[i].default_address) {
                radioAddress = resj[i].id;
            }
        }
        oldRadioAddress = radioAddress;
    // }
    

    // async function delete_address(id) {
    //     const del = await fetch(`https://medil.gr/api/v1/users/deleteAddress/` + id, {
    //         method: "DELETE",
    //     });
    //     if (del.ok) {
    //         calls();
    //     }
    // }

    // async function edit_address(i) {
    //     isEdit = true;
    //     data = resj[i];
    //     activeModalMap = true;
    // }
</script>

{#each resj as address, i}
    <div
        class="m-3 flex h-auto w-[400px] rounded-lg bg-gray-100 p-3 px-3 hover:bg-gray-200"
    >
        <input
            type="radio"
            bind:group={radioAddress}
            value={address.id}
            name="groupAddress"
            id={address.id}
            class="mt-1 h-6 w-6 border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
            
        />
        <!-- on:click={()=>changeDefault(oldRadioAddress, address.id)} -->
        <label for={address.id} class="flex w-full justify-between">
            <div class="ml-3 block">
                <div class="text-lg font-semibold text-gray-600">
                    {address.address}
                    {address.number}, {address.city}
                </div>
                {#if info != null}
                    <div class="text-base text-gray-500 font-normal mt-1">
                        {info.last_name}
                        {info.first_name}
                    </div>
                {/if}
                {#if radioAddress == address.id}
                    <div class="text-base text-gray-500 font-normal">
                        Τ.Κ. {address.tk}, {address.place}
                    </div>
                    <div class="text-base text-gray-500 font-normal">
                        {address.name}
                    </div>
                    <div class="text-base text-gray-500 font-normal">
                        Τηλ. {address.tel}
                    </div>
                {/if}
            </div>
        </label>
        <!-- <a
            on:click={() => edit_address(i)}
            class="text-blue-700 font-semibold cursor-pointer mr-2">✎</a
        >
        <a
            on:click={() => delete_address(address.id)}
            class="text-red-700 font-semibold cursor-pointer">🗑</a
        > -->
    </div>
<!-- {:else}
    <p>loading...</p> -->
{/each}

<!-- <div class="mr-2 my-2 inline-block w-full">
    <button
        type="button"
        class="w-full rounded-md py-2.5 px-5 text-sm text-blue-600 bg-blue-100 hover:bg-blue-200 focus:outline-none"
        on:click={() => (activeModalMap = true)}
        >Προσθήκη καινούριας διεύθυνσης</button
    >
</div> -->

<!-- <Dialog
    class="p-4 !bg-white max-h-[800px]"
    width={650}
    bind:active={activeModalMap}
>
    <NewAddress bind:closeModal={activeModalMap} bind:isEdit bind:data />
</Dialog> -->