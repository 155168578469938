<script>
    import EditorTiny from '@tinymce/tinymce-svelte';
    import {onMount} from 'svelte'
    export let value = "";
    let editorVal='';
    // export let id="abc";
    // export let editor_id=null;
    // let history_id=1;
    // let x=0;
    // onMount(() => {
    //     editorVal=val
    // });
    let conf={
        height : "480",
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | emoticons |  print |',
        toolbar_sticky: true,
        // language: 'el'
    }
    // $: console.log(value);
    // function initTiny(){
    //     console.log("init!!!!");
    // }
    // $: if(editor_id!=history_id){
    //     history_id=editor_id;
    //     if(x=1){
    //         x=2
    //     }else{
    //         x=1
    //     }
    // }

</script>
<!-- {#if x=1} id={editor_id}-->
    <EditorTiny apiKey="7gxq6za55laxjicwu87hyo7j0p7228hcw4o75dcjc6jwf0pb" {conf} bind:value={value}/>
<!-- {/if}
{#if x=2}
    <EditorTiny id={editor_id} apiKey="7gxq6za55laxjicwu87hyo7j0p7228hcw4o75dcjc6jwf0pb" {conf} bind:value={value}/>
{/if} -->

