<script>
    import {
        Dialog,
        Snackbar,
        Overlay,
        ProgressCircular,
        MaterialApp,
        Select,
    } from "svelte-materialify";
    import Upload from "../utils/Upload.svelte";
    import { FileDropzone } from "attractions";

    export let idb = null,
        gxid = null,
        product = null;
    let dialogShowAdd = false;
    let snackbar = false;
    let pdf = [];
    let editId = false;
    let reqPdf = true;
    let active = false;
    let nameGroup = "";
    let nameFile = "";
    let group = [];
    let secure = false;
    if (product.files != null) {
        nameGroup = product.files.name;
    }

    async function getEidikotites() {
        const res = await fetch("/api/v1/settings/fr/2");
        const resj = await res.json();
        return resj;
    }

    async function addFile() {
        active = true;
        // const uploadImage = await imageArxeio.uploads_img();
        try {
            let position = null;
            let path;
            if (editId) {
                for (let i = 0; i < product.files.items.length; i++) {
                    if (product.files.items[i].id == editId) {
                        position = i;
                    }
                }
                if (product.files.items[position].path != pdf[0].name) {
                    const formData = new FormData();
                    formData.append("myFile", pdf[0]);
                    const post_file = await fetch(
                        "/api/v1/settings/fileupload/files" +
                            (secure ? "" : "?secure=true"),
                        {
                            method: "POST",
                            credentials: "include",
                            body: formData,
                        }
                    );
                    path = await post_file.json();
                } else {
                    path = {
                        path: product.files.items[position].path,
                        status: true,
                    };
                }
            } else {
                const formData = new FormData();
                formData.append("myFile", pdf[0]);
                const post_file = await fetch(
                    "/api/v1/settings/fileupload/files" +
                        (secure ? "" : "?secure=true"),
                    {
                        method: "POST",
                        credentials: "include",
                        body: formData,
                    }
                );
                path = await post_file.json();
            }

            if (!path.status) {
                throw new Error();
            }

            if (!editId) {
                if (product.files == null) {
                    product.files = { name: "", items: [] };
                }
                product.files.name = nameGroup;
                product.files.items.push({
                    id: Math.floor(Math.random() * 10000),
                    path: secure
                        ? "/api/v1/settings/private/" + path.path
                        : "https://medil.gr/site_images/uploads/" + path.path,
                    name: nameFile,
                    secure: secure,
                    group: group,
                });
            } else {
                product.files.name = nameGroup;
                product.files.items[position] = {
                    id: editId,
                    path: secure
                        ? "/api/v1/settings/private/" + path.path
                        : "https://medil.gr/site_images/uploads/" + path.path,
                    name: nameFile,
                    secure: secure,
                    group: group,
                };
            }

            await saveToDb();
            dialogShowAdd = false;
            nameFile = "";
            pdf = [];
            editId = false;
            reqPdf = true;
            active = false;
            secure = false;
            group = [];
        } catch (e) {
            dialogShowAdd = false;
            nameFile = "";
            pdf = [];
            editId = false;
            snackbar = true;
            console.log(e);
            reqPdf = true;
            active = false;
            secure = false;
            group = [];
        }
    }

    async function saveToDb() {
        const save = await fetch(
            "/api/v1/shop/product/partial/" + idb + "/" + gxid,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    files: product.files,
                }),
            }
        );
        product.files = product.files;
    }

    async function deleteFile(id) {
        for (let i = 0; i < product.files.items.length; i++) {
            if (product.files.items[i].id == id) {
                product.files.items.splice(i, 1);
            }
        }
        if(!product.files.items.length){
            product.files=null;
        }
        await saveToDb();
    }

    function closeModal() {
        dialogShowAdd = false;
        nameFile = null;
        pdf = [];
        editId = false;
        reqPdf = true;
        secure = false;
        group = [];
    }
</script>

<Dialog class="white p-4" bind:active={dialogShowAdd} persistent>
    <Overlay absolute {active}>
        <ProgressCircular color="white" indeterminate size={128} />
    </Overlay>
    <div class="text-center text-xl">Προσθήκη αρχείου</div>
    <form on:submit|preventDefault={addFile}>
        <div class="my-6 px-4">
            <label
                for="default-input"
                class="block mb-2 text-sm font-medium text-gray-900"
                >Όνομα αρχείου</label
            >
            <input
                bind:value={nameFile}
                required
                type="text"
                id="default-input"
                class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
        </div>
        <div class="my-6 px-4">
            {#await getEidikotites() then items}
                <MaterialApp>
                    <Select outlined {items} multiple bind:value={group}
                        >Ειδικότητες</Select
                    >
                </MaterialApp>
            {/await}
        </div>
        <div class="flex items-center my-3 px-4">
            <input
                id="default-checkbox"
                type="checkbox"
                bind:checked={secure}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
            />
            <label
                for="default-checkbox"
                class="ml-2 text-sm font-medium text-gray-900"
                >Είναι μόνο για συνδεδεμένους</label
            >
        </div>

        <!-- <div class="h-36">
            <Upload
                row="true"
                {imageExist}
                bind:this={imageArxeio}
                multipleImage="false"
                name="Εικόνα αρχείου (προαιρετικό)"
                type="files"
                required={false}
            />
        </div> -->
        <div class="h-36 my-2 px-4">
            <FileDropzone
                accept=".pdf"
                max={1}
                bind:files={pdf}
                required={reqPdf}
            />
        </div>
        <div class="flex justify-around">
            <button
                type="submit"
                class="bg-blue-500 text-white p-3 rounded-xl outline-none ring-offset-2 ring-2 ring-blue-300 hover:bg-blue-600 active:bg-blue-700"
            >
                Αποθήκευση
            </button>
            <button
                type="button"
                class="bg-red-600 text-white p-3 rounded-xl outline-none ring-offset-2 ring-2 ring-red-300 hover:bg-red-700 active:bg-red-800"
                on:click={closeModal}
            >
                Cancel
            </button>
        </div>
    </form>
</Dialog>
<div class="p-8">
    <div class="text-xl text-green-800 my-6 flex items-center ">
        Περιγραφή
        <input
            id="name"
            class="bg-blue-100 rounded-md mx-1 px-1"
            bind:value={nameGroup}
        />
        <label for="name">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                />
            </svg>
        </label>
        <button
            on:click={() => {
                dialogShowAdd = true;
                nameFile = "";
                pdf = [];
            }}
            class="bg-green-600 rounded-md text-white text-xl p-1 mx-2 hover:bg-green-700 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
            >+ Προσθήκη αρχείου</button
        >
        <!-- <button
            on:click={saveToDb}
            class="bg-red-600 rounded-md text-white text-xl p-1 mx-2 hover:bg-red-700 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
            >Αποθήκευση</button
        > -->
    </div>

    {#if product.files != null}
        <div class="grid grid-cols-5 gap-4">
            {#each product.files.items as subitem}
                <div class="relative">
                    <img
                        src="https://medil.gr/site_images/pdf.jpg"
                        class="h-24 w-24 rounded-lg"
                        alt=""
                    />
                    <button
                        on:click={() => deleteFile(subitem.id)}
                        class="absolute top-0 bg-orange-500 text-white p-2 rounded-full hover:bg-orange-800 m-2"
                        ><svg
                            style="width:24px;height:24px"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
                            />
                        </svg></button
                    >
                    <a href={subitem.path} download="{subitem.name}.pdf">
                        <button
                            class="absolute top-10 bg-green-500 text-white p-2 rounded-full hover:bg-green-800 m-2"
                            ><svg
                                style="width:24px;height:24px"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
                                />
                            </svg></button
                        >
                    </a>
                    <div class="">
                        <div class="text-base">{subitem.name}</div>
                        <div class="text-sm">
                            Μονο για συνδεδεμένους: {subitem.secure
                                ? "NAI"
                                : "OXI"}
                        </div>
                    </div>
                </div>
            {/each}
        </div>
    {:else}
        Δεν υπάρχουν κατάλογοι...
    {/if}
</div>

<Snackbar
    class="flex-column red text-white"
    bind:active={snackbar}
    bottom
    center
    timeout={3000}
>
    Υπήρξε προβλημα με το αρχείο του καταλόγου!
</Snackbar>
