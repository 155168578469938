<script>
    // @ts-check
    import { Button } from "svelte-materialify";
    import { createEventDispatcher } from "svelte";
    import imageCompression from 'browser-image-compression';


    const dispatch = createEventDispatcher();
    let avatar = null,
        fileinput = null;
    let temp = [];
    let img = [];
    let image_paths = [];
    let image_path;
    export let row = false;
    export let col = false;
    export let imageExist = null;
    export let imagesExist = [null];
    export let multipleImage = false;
    export let type = "pros";
    export let name="Εικόνα προϊόντος";
    export let required=false;
    function blobToFile(myBlob, fileName){
        // //A Blob() is almost a File() - it's just missing the two properties below which we will add
        // theBlob.lastModifiedDate = new Date();
        // theBlob.name = fileName;
        // return theBlob;
        const myFile = new File([myBlob], fileName, {
            type: myBlob.type,
        });
        return myFile;
    }

    const onFileSelected = async (e) => {
        let image = e.target.files[0];
        let imageName=image.name;

        const options = {
                maxSizeMB: 0.6,
                maxWidthOrHeight: 900,
                useWebWorker: true,
                fileType:'image/jpeg'
            }
        try {
            image = await imageCompression(image, options);
        } catch (error) {
            console.log(error);
        }
        image =  blobToFile(image, imageName.slice(0, imageName.lastIndexOf('.'))+".jpg");

        if (image != null && multipleImage) {
            img.push(image);
            console.log("img array", img.length);
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (e) => {
                avatar = e.target.result;
                temp.push(avatar);
                temp = temp;
            };
        } else if (image != null && !multipleImage) {
            img = [];
            img.push(image);
            // console.log(img.length);
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (e) => {
                avatar = e.target.result;
                temp = [];
                temp.push(avatar);
                temp = temp;
            };
        }
    };

    export async function uploads_img() {
        // console.log("upload 1");
        if (img.length) {
            // console.log("upload 2");
            image_paths = [];
            for (let i = 0; i < img.length; i++) {
                // console.log("upload "+i);
                const formData = new FormData();
                formData.append("myFile", img[i]);

                const post_image = await fetch("/api/v1/shop/imageup/" + type, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        Authorization:
                            "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    },
                    body: formData,
                });

                image_path = await post_image.json();
                image_paths.push(image_path.path);
            }
            img = [];
            if (row) {
                return image_paths[0];
            } else {
                if (Array.isArray(imagesExist) && imagesExist.length) {
                    const concat = image_paths.concat(imagesExist);
                    return concat;
                } else {
                    return image_paths;
                }
            }

            // console.log(image_paths);
            dispatch("completeUpload");
        } else {
            if (row) {
                return imageExist;
            } else {
                return imagesExist;
            }
        }
    }

    function delImg(img, pos){
        console.log(pos);
        if(img == imagesExist[pos]){
            imagesExist.splice(pos, 1)
        }
        imagesExist=imagesExist;
    }
</script>

<!-- --------------------------------- -->

{#if col}
    <div id="app">
        {#if imagesExist != null && imagesExist.length}
            {#each imagesExist as imagesEx, i}
                <div class="relative">
                    <img
                        class="avatar"
                        src="https://medil.gr/site_images/{imagesEx}"
                        alt="d"
                    />
                    <button class="absolute top-0 bg-red-500 text-white p-2 rounded hover:bg-red-800 m-2 rounded-full" on:click={()=>delImg(imagesEx, i)}>
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                        </svg>
                    </button>
                </div>
            {/each}
        {/if}
        {#if avatar}
            {#each temp as av}
                <img class="avatar" src={av} alt="d" />
            {/each}
        {/if}
        <div class="up">
            <img
                class="upload"
                src="https://static.thenounproject.com/png/625182-200.png"
                alt=""
                on:click={() => {
                    fileinput.click();
                }}
            />
            <div
                class="chan"
                on:click={() => {
                    fileinput.click();
                }}
            >
                Choose Image
            </div>
            <input
                style="display:none"
                type="file"
                accept=".jpg, .jpeg, .png, .gif"
                on:change={(e) => onFileSelected(e)}
                bind:this={fileinput}
                required={required}
            />
        </div>
        <Button depressed block class="primary-color" on:click={uploads_img}
            >αποθηκευση</Button
        >
    </div>
{/if}

<!-- ------------------------------- -->

{#if row}
    <div
        class="flex justify-center image bg-white overflow-hidden mx-6 rounded-lg border-2 border-teal-700 p-2"
    >
        <div class="w-1/2 flex justify-center">
            {#if avatar}
                <img
                    class="object-scale-down w-full h-32"
                    src={avatar}
                    alt="d"
                />
            {:else if imageExist != null && imageExist.length}
                <img
                    class="object-scale-down w-full h-32"
                    src="https://medil.gr/site_images/{imageExist}"
                    alt=""
                />
            {:else}
                <img
                    class="object-scale-down w-full h-32"
                    src="https://images.vexels.com/media/users/3/131734/isolated/preview/05d86a9b63d1930d6298b27081ddc345-photo-preview-frame-icon.png"
                    alt=""
                />
            {/if}
        </div>
        <div id="approw" class="w-1/2 flex justify-center">
            <h5>{name}</h5>
            <img
                class="uploadrow"
                src="https://static.thenounproject.com/png/625182-200.png"
                alt=""
                on:click={() => {
                    fileinput.click();
                }}
            />
            <div
                class="chan"
                on:click={() => {
                    fileinput.click();
                }}
            >
                Choose Image
            </div>
            <input
                style="display:none"
                type="file"
                accept=".jpg, .jpeg, .png"
                on:change={(e) => onFileSelected(e)}
                bind:this={fileinput}
                required={imageExist!=null?false:required}
            />
        </div>
    </div>
{/if}

<!-- ------------------------------- -->
<style>
    #app {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }
    .up {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }

    .upload {
        display: flex;
        height: 50px;
        width: 50px;
        cursor: pointer;
    }
    .avatar {
        display: flex;
        margin: 3px;
        height: 200px;
        width: 200px;
        border-radius: 6px;
        object-fit: scale-down;
    }
    #approw {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }

    .uploadrow {
        display: flex;
        height: 50px;
        width: 50px;
        cursor: pointer;
    }
</style>
