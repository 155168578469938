<script>
    import { user_logon, user_role } from "./comp/utils/stores.js";
    import { onMount } from "svelte";
    import {
        Card,
        CardTitle,
        CardSubtitle,
        CardActions,
        Button,
        Icon,
        Divider,
        MaterialApp,
    } from "svelte-materialify";
    import { slide } from "svelte/transition";
    import { mdiChevronDown } from "@mdi/js";
    import { flip } from "svelte/animate";
    // import router from "page";

    // console.log($user_logon +" "+ $user_role)

    // if( !$user_logon && $user_role != 'Admin'){
    //     router.redirect("/login");
    // }

    let active = false;
    function toggle() {
        active = !active;
    }

    const apiUrl = "/api/v1/shop/categories/top";
    let list = [];

    onMount(async () => {
        const response = await fetch(apiUrl);
        list = await response.json();
        console.log(list);
    });

    let hovering = false;

    const drop = (event, target) => {
        event.dataTransfer.dropEffect = "move";
        const start = parseInt(event.dataTransfer.getData("text/plain"));
        const newTracklist = list;

        if (start < target) {
            newTracklist.splice(target + 1, 0, newTracklist[start]);
            newTracklist.splice(start, 1);
        } else {
            newTracklist.splice(target, 0, newTracklist[start]);
            newTracklist.splice(start + 1, 1);
        }
        list = newTracklist;
        hovering = null;
        // console.log(list.length);
        // reorder(list);
    };

    // async function reorder(order){
    //     console.log("ok  1 "+ order);
    //     for(let i = 0; i < order.lenght; i++){
    //         console.log("ok " + order[i].id);
    //     }
    // }

    const dragstart = (event, i) => {
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.dropEffect = "move";
        const start = i;
        event.dataTransfer.setData("text/plain", start);
    };
</script>

<div class="container mx-auto px-4 pb-4">
    <h3 class="py-4">Κατηγορίες προϊόντων:</h3>
    <div
        class="grid grid-cols-1 md:grid-cols-5 grid-flow-row auto-rows-max gap-4 md:auto-rows-min"
    >
        {#each list as item, index (item.id)}
            <div
                class="list-item" 
                style="list-style: none;"
                animate:flip
                draggable={true}
                on:dragstart={(event) => dragstart(event, index)}
                on:drop|preventDefault={(event) => drop(event, index)}
                ondragover="return false"
                on:dragenter={() => (hovering = index)}
                class:is-active={hovering === index}
            >
                <div
                    class="h-full image bg-white border-4 border-transparent rounded-lg shadow-lg overflow-hidden hover:shadow-2xl hover:scale-105 transition-all transform duration-300 hover:border-blue-400"
                >
                    {#if item.category_level == "products"}
                        <a href="/products/{item.id}">
                            <img
                                class="object-cover w-full h-64"
                                src="https://medil.gr/site_images/{item.category_image}"
                                alt={item.category_name}
                            />
                            <span
                                class="text-center text-md block font-semibold py-2 px-6 text-gray-600 hover:text-blue-900"
                                >{item.category_name}</span
                            >
                        </a>
                    {:else}
                        <a href="/admin_med/shop/{item.id}">
                            <img
                                class="object-cover w-full h-64"
                                src="https://medil.gr/site_images/{item.category_image}"
                                alt={item.category_name}
                            />
                            <span
                                class="text-center text-md block font-semibold py-2 px-6 text-gray-600 hover:text-blue-900"
                                >{item.category_name}</span
                            >
                        </a>
                    {/if}
                </div>
            </div>
        {/each}
    </div>
</div>

<style>
      .list-item.is-active {
    background-blend-mode: #3273dc;

    opacity: 0.1;
  }
</style>
