<script>
    import { onMount } from "svelte";
    import { Chip, Icon, Button, 
    Dialog,
    Card,
    CardTitle,
    CardText,
    CardActions,
    MaterialApp  } from "svelte-materialify";
    import {
        mdiLabelOutline,
        mdiCheckboxMarked,
        mdiCloseBox,
        mdiBarcodeScan,
    } from "@mdi/js";
    import { user_logon, user_role } from "./comp/utils/stores.js";
    import { flip } from "svelte/animate";
    import {
        NotificationDisplay,
        notifier,
    } from "@beyonk/svelte-notifications";
    import router from "page";
    import Modal, { getModal } from "./comp/utils/Modal.svelte";
    import { mdiDelete, mdiPencil } from "@mdi/js";
    import SearchProduct from "./comp/products/SearchProduct.svelte";
    import CreateProduct from "./comp/products/ProductCreate.svelte";
    import ProductTabs from './comp/products/ProductTabs.svelte';

    if (!$user_logon && $user_role != "Admin") {
        console.log($user_logon + "  " + $user_role);
        router.redirect("/");
    }
    
    let list = [];
    export let params;
    var client_login = false;
    let init = false;
    let active_mtf_modal = false;
    let p_del_id;
    let del_name_product = '';
    let id_transfer= null;
    let gxid_transfer= null;
    let editProductDialog=false;
    let category=null;
    let itemTransfer=null;

    
    onMount(async () => {
        const resCatInfo = await fetch(
            "https://admin.medil.gr/api/v1/shop/categories/childs?ids=[" + params.cid + "]"
        );
        category = await resCatInfo.json();
        fetchMain();
    });

    async function fetchMain(){
        const apiUrl = "/api/v1/shop/products/";
        const response = await fetch(apiUrl + params.cid);
        list = await response.json();
        if (response.ok) {
            try{
                client_login = list[1].hasOwnProperty("gx_formula");
                return list;
            }catch(error){
                return list;
            }
        } else {
            throw new Error(result);
        }
    }

    let hovering = false;

    const drop = (event, target) => {
        event.dataTransfer.dropEffect = "move";
        const start = parseInt(event.dataTransfer.getData("text/plain"));
        const newTracklist = list;

        if (start < target) {
            newTracklist.splice(target + 1, 0, newTracklist[start]);
            newTracklist.splice(start, 1);
        } else {
            newTracklist.splice(target, 0, newTracklist[start]);
            newTracklist.splice(start + 1, 1);
        }
        list = newTracklist;
        hovering = null;
    };

    const dragstart = (event, i) => {
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.dropEffect = "move";
        const start = i;
        event.dataTransfer.setData("text/plain", start);
    };


    async function reorder(relist) {
        if ($user_logon && $user_role == "Admin") {
            let order = [];
            for (let z = 0; z < relist.length; z++) {
                order.push({cat_id: params.cid, pro_id: relist[z].id_product, order: z});
            }
            try {
                const res = await fetch(
                    `/api/v1/shop/products/reorder`,
                    {
                        method: "POST",
                        credentials: "include",
                        headers: {
                            Authorization:
                                "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                                "Content-Type": "application/json"
                        },
                        body: JSON.stringify(order)
                    }
                );
                let json = await res.json();
                if (res.ok) {
                    // alert = true;
                    notifier.success(json.message, 4000);
                }
            } catch (error) {
                notifier.warning("Something went wrong...", 4000);
            }
            console.log(order);
        } else {
            notifier.warning("Πρέπει να συνδεθείτε.", 4000);
        }
    }

    async function add_product(new_list){
        // try{
            if(new_list!=undefined){
                // for(let i=0;i<new_list.length; i++){
                //     await fetch('/api/v1/shop/add/product/to/category/'+params.cid+'/'+new_list[i].id_product);
                // }
                list=new_list;
            }
        // }catch(error){
        //     console.log("error")
        // }
    }

    function create_edit_prod(){

    }

    function setDelId(id2, name){
        p_del_id=id2;
        del_name_product = name;
    }

    function open_mtf_modal(){
        active_mtf_modal=true;
    }

    function close_mtf_modal(){
        active_mtf_modal=false;
    }

    async function deleteprod(plist, pdid, del_type){
        console.log(plist+ ' '+ pdid + ' '+ del_type);
        try {
                const res = await fetch(
                    '/api/v1/shop/product/delete/'+del_type+'/'+params.cid+'/'+ pdid,
                    {
                        method: "DELETE",
                        credentials: "include",
                        headers: {
                            Authorization:
                                "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                                "Content-Type": "application/json"
                        }
                    }
                );
                let json_delete = await res.json();
                if (res.ok) {
                    // alert = true;
                    notifier.success(json_delete.message, 4000);
                    for (let j = 0; j < plist.length; j++) {
                            if (plist[j].id_product == pdid) {
                                plist.splice(j, 1);
                                list = plist;
                                break;
                            }
                        }
                }
            } catch (error) {
                notifier.warning("Something went wrong...", 4000);
                // notifier.warning(error, 4000);
            }
    }

        function select_product(id_input, gxid_input, i){
        id_transfer=id_input;
        gxid_transfer=gxid_input;
        itemTransfer= i;
    }

</script>

<div class="container mx-auto">
    <div class="my-2">
        <h4 class="mb-2">
            {#if category!=null}
                {category[0].category_name}
            {/if}
        </h4>
    <NotificationDisplay />

    <Modal id="search_product" on:close_modal={()=> console.log("modal close")}>
        <!-- {#if cat_id_edit!= null} -->
        <SearchProduct bind:list={list}/>
        <!-- {/if} -->
    </Modal>    

    
    <Modal id="create_product" minwidth='925px'>
        <!-- {#if cat_id_edit!= null} -->
        <CreateProduct/>
        <!-- {/if} -->
    </Modal>    

    <!-- <Modal id="edit_product2" minwidth='75%'>
        {#if id_transfer!=null}
        <ProductTabs idbTab={id_transfer} gxidTab={gxid_transfer} edit=true />
        {/if}
    </Modal>    -->

    <MaterialApp>
        <Dialog bind:active={editProductDialog} width={1200} class="pa-1" on:outrostart={()=>{reorder(list); fetchMain();}}>
            {#if id_transfer!=null}
            <ProductTabs idbTab={id_transfer} gxidTab={gxid_transfer} product={itemTransfer} edit=true />
            {/if}
        </Dialog>
    </MaterialApp>


    <Button class="red white-text" on:click={reorder(list)}>Αποθηκευση</Button>
    <span
        ><Button class="blue white-text" on:click={() => getModal('search_product').open(add_product)}
            >Προσθηκη υπαρχοντος προιοντος</Button
        ></span>
        <span
        ><Button class="green white-text" on:click={()=>getModal('create_product').open()} 
            >Προσθηκη καινουριου προιοντος</Button
        ></span>
    </div>
    <div
        class="grid grid-cols-1 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-flow-row auto-rows-max gap-4 md:auto-rows-min"
    >

    
        <!-- {#await response}
    {:then data} -->
        {#each list as item, index (item.id_product)}
        
            <div
                class="list-item"
                style="list-style: none;"
                animate:flip
                draggable={true}
                on:dragstart={(event) => dragstart(event, index)}
                on:drop|preventDefault={(event) => drop(event, index)}
                ondragover="return false"
                on:dragenter={() => (hovering = index)}
                class:is-active={hovering === index}
            >                
                    <div 
                        class="h-full p-4 border-4 border-transparent bg-white rounded-xl shadow-md hover:shadow-lg hover:scale-105 transition-all transform duration-500 hover:border-blue-600"
                    >
                    <!-- <div class="list-item"> -->
                    <a href="/p/{item.id_product}/{item.id_galaxy}/{item.product_slug}">
                        <img
                            class="object-cover w-full h-52 rounded-t-md"
                            src="https://medil.gr/site_images/{item.image_product}"
                            alt={item.product_slug}
                        />
                        <div class="mt-2 flex flex-col justify-between h-3/5">
                            <div>
                                <div class="h-12">
                                    <h1
                                        class="leading-tight text-lg font-bold text-gray-700 line-clamp-2"
                                    >
                                        {item.name_product}
                                    </h1>
                                </div>
                                <div class="line-clamp-4">
                                    {#if item.short_doc!= undefined && item.short_doc!=null}
                                        {@html item.short_doc.replaceAll("<p>", `<p class="mb-0 font-semibold leading-4">`)}
                                    {/if}
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>
                                        <Chip
                                            outlined
                                            class="mt-4 secondary-text"
                                            size="small"
                                        >
                                            <Icon path={mdiLabelOutline} />
                                            <span
                                                ><strong
                                                    >{item.product_brand}</strong
                                                ></span
                                            ></Chip
                                        >
                                        <Chip
                                            outlined
                                            class="mt-4 red-text"
                                            size="small"
                                        >
                                            <Icon path={mdiBarcodeScan} />
                                            <span><strong>{item.code}</strong></span
                                            ></Chip
                                        >
                                    </span>
                                </div>
                                <div class="pt-4 pb-1">
                                    {#if client_login}
                                        {#if item.balance > 10}
                                            <Button
                                                depressed
                                                block
                                                disabled
                                                size="small"
                                                class="green white-text"
                                                ><Icon path={mdiCheckboxMarked} />
                                                <span class="pl-1">ΔΙΑΘΕΣΙΜΟ</span
                                                ></Button
                                            >
                                        {/if}
                                        {#if item.balance < 10 && item.balance > 0}
                                            <Button
                                                depressed
                                                block
                                                disabled
                                                size="small"
                                                class="primary-color white-text"
                                                ><Icon path={mdiCheckboxMarked} />
                                                <span class="pl-1"
                                                    >ΤΕΛΕΥΤ. ΤΕΜΑΧΙΑ</span
                                                ></Button
                                            >
                                        {/if}
                                        {#if item.balance <= 0}
                                            <Button
                                                depressed
                                                block
                                                disabled
                                                size="small"
                                                class="red white-text"
                                                ><Icon path={mdiCloseBox} /><span
                                                    class="pl-1">ΣΕ ΕΛΛΕΙΨΗ</span
                                                ></Button
                                            >
                                        {/if}
                                    {/if}
                                </div>

                                <div
                                    class="mt-4 mb-2 flex justify-between pl-4 pr-2"
                                >
                                    <div>
                                        {#if client_login}
                                            {#if item.gx_formula != null}
                                                <div class="text-gray-700 text-lg">
                                                    Τιμή τεμ. {item.gx_formula}€
                                                </div>

                                                <div class="text-gray-400 text-sm">
                                                    {#if item.vat_desc != null}
                                                        Τιμή + ΦΠΑ {(
                                                            (item.gx_formula /
                                                                100) *
                                                                item.vat_desc.match(
                                                                    /\d+/
                                                                )[0] +
                                                            item.gx_formula
                                                        ).toFixed(2)}€
                                                    {:else}
                                                        Τιμή + ΦΠΑ --€
                                                    {/if}
                                                </div>
                                            {:else}
                                                <div class="text-gray-700 text-lg">
                                                    Τιμή τεμ. {item.price2}€
                                                </div>
                                                <div class="text-gray-400 text-sm">
                                                    {#if item.vat_desc != null}
                                                        Τιμή + ΦΠΑ {(
                                                            (item.price2 / 100) *
                                                                item.vat_desc.match(
                                                                    /\d+/
                                                                )[0] +
                                                            item.price2
                                                        ).toFixed(2)}€
                                                    {:else}
                                                        Τιμή + ΦΠΑ --€
                                                    {/if}
                                                </div>
                                            {/if}
                                        {/if}
                                    </div>
                                    <button
                                        class="text-lg block font-semibold py-2 px-6 text-green-100 hover:text-white bg-green-400 rounded-lg shadow hover:shadow-md transition duration-300"
                                        >Buy</button
                                    >
                                </div>
                            </div>
                        </div>
                    </a>
                        <div class="absolute top-0 right-0 p-2">
                            <Button
                                fab
                                size="small"
                                class="orange white-text"
                                on:click={select_product(item.id_product, item.id_galaxy, item)} on:click={()=>editProductDialog=true} 
                            >
                            <!-- getModal('edit_product2').open() -->
                                <Icon path={mdiPencil} />
                            </Button>
                            <Button
                                fab
                                size="small"
                                class="red white-text"
                                on:click={open_mtf_modal} on:click={setDelId(item.id_product, item.name_product)}
                            >
                                <Icon path={mdiDelete} />
                            </Button>
                        </div>
                    
                    </div>                
                <!-- </div> -->
            </div>
        {/each}
    </div>
</div>

<MaterialApp>
<Dialog persistent bind:active={active_mtf_modal} width=520px>
    <Card>
      <CardTitle>{del_name_product}</CardTitle>
      <CardText>
        Επιλέξτε μία απο τις παρακάτω <strong>ενέργειες διαγραφής</strong> για το προιόν.
        Προσοχή δεν υπαρχει δυνατότητα αναίρεσης!
      </CardText>
      <CardActions>
        <Button on:click={close_mtf_modal} outlined class="black-text">ΑΚΥΡΟ</Button>
        <Button on:click={deleteprod(list,p_del_id, 'permantly')} on:click={close_mtf_modal} text class="red-text">Οριστικη διαγραφη</Button>
        <Button on:click={deleteprod(list,p_del_id,'temporary')} on:click={close_mtf_modal} text class="blue-text">Διαγραφη απο κατηγορια</Button>
      </CardActions>
    </Card>
  </Dialog>
  </MaterialApp>

<style>
      .list-item.is-active {
          border-radius: 5px;
    background-color: #ff00f2;
    color: #fff;
  }
  a {
  text-decoration: none;
}
</style>