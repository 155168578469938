<script>
    import {
        Row,
        Col,
        TextField,
        Select,
        ProgressCircular,
        Button,
        MaterialApp,
        Radio,
    } from "svelte-materialify";
    // import Carousel from "@beyonk/svelte-carousel";
    // import { ChevronLeftIcon, ChevronRightIcon } from "svelte-feather-icons";

    export let id;
    let category = null;
    let categories = null;
    let avatar, fileinput;
    const cat_type = [
        { label: "Αρχική", value: "top" },
        { label: "Υποκατηγορίες", value: "sub" },
        { label: "Προϊόντα", value: "products" },
    ];
    const roles = ["Roloi erxontai!", "Υποκατηγορίες", "Προϊόντα"];
    let group;
    let guests;

    $: if (id != null) {
        const fetch = cat_fetch(id);
        avatar = null;
        fileinput = null;
    }

    async function cat_fetch(id) {
        category = null;
        categories = null;
        try {
            const res = await fetch(
                `/api/v1/shop/categories/id/` + id,
                {
                    /// api/v1/users /api/v1/users
                    method: "GET",
                    credentials: "include",
                    headers: {
                        Authorization:
                            "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    },
                }
            );
            const res1 = await fetch(
                `/api/v1/shop/categories/childs/` + id
            );
            category = await res.json();
            categories = await res1.json();
            group = category.category_level;
            guests= category.cat_is_for_guest;
            console.log(guests);
            console.log(category);
        } catch (error) {
            // console.error(error);
        }
    }
    let img = null;
    const onFileSelected = (e) => {
        var image = e.target.files[0];
        img=image;
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
            avatar = e.target.result;
        };
    };

    let image_path=null;

    async function update_cat() {
        if(img!=null){
        const formData = new FormData();
        formData.append('myFile', img);
        
        const post_image = await fetch("/api/v1/shop/imageup/cats", {
            method: "POST",
            credentials: "include",
            headers: {
                Authorization: "8UKDHJ_medil_by_andre_koukouroukou 56526658741"
            },
            body: formData
        });

         image_path = await post_image.json();
    }else{
         image_path = [{path: category.category_image}];
    }
        // console.log(category.category_name);

        const res_category_update = await fetch(
            "/api/v1/shop/categories/" + id,
            {
                method: "PATCH",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                        "Content-Type": "application/json",
                        Accept: "application/json"
                },
                body: JSON.stringify({
                    id: category.id,
                    category_name: category.category_name,
                    category_slug: category.category_slug,
                    category_image: image_path.path,                    
                    category_level: group,
                    cat_accepted_roles: category.cat_accepted_roles,
                    cat_is_for_guest: guests,
                }),
            }
        );
    }
</script>

{#if category != null}
    <div
        class="flex justify-center image bg-white rounded-lg overflow-hidden mx-8"
    >
        <div class="w-1/2 flex justify-center">
            {#if avatar}
                <img class="object-scale-down w-full h-64" src={avatar} alt="d" />
            {:else}
                <img
                    class="object-scale-down w-full h-64"
                    src="https://medil.gr/site_images/{category.category_image}"
                    alt=""
                />
            {/if}
        </div>
        <div id="app" class="w-1/2 flex justify-center">
            <h5>Upload Image</h5>
            <img
                class="upload"
                src="https://static.thenounproject.com/png/625182-200.png"
                alt=""
                on:click={() => {
                    fileinput.click();
                }}
            />
            <div
                class="chan"
                on:click={() => {
                    fileinput.click();
                }}
            >
                Choose Image
            </div>
            <input
                style="display:none"
                type="file"
                accept=".jpg, .jpeg, .png"
                on:change={(e) => onFileSelected(e)}
                bind:this={fileinput}
            />
        </div>
    </div>
    <div class="m-8">
        <MaterialApp>
            <TextField bind:value={category.category_name} filled
                >Όνομα κατηγορίας *</TextField
            >
            <TextField bind:value={category.category_slug} filled
                >Slug (αγγλικά μικρά χωρίς κενά)</TextField
            >
            <Select filled items={roles} value="">Αποδεκτές ειδικότητες</Select>
            <div class="my-4">
                <div
                    class="d-flex justify-space-around rounded-md bg-gray-100 h-16"
                >
                    <Radio bind:group={guests} value={0} color="red"
                        >Είναι μόνο για πελάτες</Radio
                    >
                    <Radio
                        bind:group={guests}
                        value={1}
                        color="secondary"
                        >Είναι και για επισκέπτες</Radio
                    >
                </div>
            </div>
            <div class="my-4">
                Τύπος κατηγορίας:
                <div class="d-flex justify-space-around">
                    <Radio bind:group value={'top'} color="red"
                        >Αρχική κατηγορία</Radio
                    >
                    <Radio bind:group value={'sub'} color="secondary"
                        >Υποκατηγορία</Radio
                    >
                    <Radio bind:group value={'products'} color="success"
                        >Προϊόντα</Radio
                    >
                </div>
            </div>
        </MaterialApp>
    </div>

    <div class="flex justify-center bg-blue-400 mx-8">Υποκατηγορίες:</div>

    <div class="flex m-6 max-w-5xl min-w-1/4">
        
    </div>
    <Button
        block
        class="red white-text"
        style="position: absolute; bottom:0px; right: 0px;" on:click={update_cat}>Αποθήκευση</Button>
    <!-- <MaterialApp /> -->
{:else}
    <div class="d-flex justify-center align-center">
        <ProgressCircular size={70} width={7} indeterminate color="success" />
    </div>
{/if}

<style>
    #app {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }

    .upload {
        display: flex;
        height: 50px;
        width: 50px;
        cursor: pointer;
    }
</style>
