<script>
	import {
		Row,
		Col,
		TextField,
		Overlay,
		ProgressCircular,
		Button,
		MaterialApp,
		Icon,
		Snackbar
	} from "svelte-materialify";
	import {
		count,
		param,
		customerId,
		mapToken,
		googleApiKey,
	} from "../utils/stores.js";
	import { onDestroy } from "svelte";
	import { mdiEyeOff, mdiEye } from "@mdi/js";
	// import Autocomplete from '@smui-extra/autocomplete';
	import Select from 'svelte-select';

	const items2 = ["Admin", "SuperUser", "Client"];
	const items = ["Client", "Supplier", "Both"];
	var resultUser = null;
	var resj = null;
	export let afmData;
	export let afmModal = true;
	export let closeModalCreateUser = true;
	let show = false;
	let activeOverlay = false;
	let snackbar = false;
	let json=null;
	let snackbarMessage='';
	let snackColor='red';
	let finish=true;

	// $: console.log(category);

	const emailRules = [
    (v) => !!v || 'Required',
    (v) => v.length <= 25 || 'Max 25 characters',
    (v) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(v) || 'Invalid e-mail.';
    },
  ];

	const idikotites = [
        "Μικροβιολόγοι-Βιοπαθολόγοι",
		"Αγγειοχειρουργοί - Αγγειολόγοι",
		"Αθλητίατροι",
		"Αιματολόγοι",
		"Αλλεργιολόγοι",
		"Αναισθησιολόγοι",
		"Βελονιστές",
		"Γαστρεντερολόγοι",
		"Γενικοί Γιατροί",
		"Γενικοί Χειρουργοί",
		"Γιατροί Υπερβαρικής - Καταδυτικής Ιατρικής",
		"Γναθοχειρουργοί",
		"Γυναικολόγοι - Μαιευτήρες",
		"Δερματολόγοι - Αφροδισιολόγοι",
		"Διαβητολόγοι",
		"Διαιτολόγοι - Διατροφολόγοι",
		"Εμβρυολόγοι",
		"Ενδοδοντολόγοι",
		"Ενδοκρινολόγοι",
		"Εργοθεραπευτές",
		"Ηπατολόγοι",
		"Καρδιολόγοι",
		"Καρδιοχειρουργοί",
		"Μαστολόγοι",
		"Νευρολόγοι",
		"Νευροχειρουργοί",
		"Νευροψυχολόγοι",
		"Νεφρολόγοι",
		"Ογκολόγοι",
		"Οδοντίατροι",
		"Ορθοδοντικοί",
		"Ορθοπαιδικοί και Ορθοπαιδικοί Χειρουργοί",
		"Ουρολόγοι - Ανδρολόγοι",
		"Οφθαλμίατροι",
		"Παθολόγοι",
		"Παιδίατροι",
		"Παιδογαστρεντερολόγοι",
		"Παιδοδιαβητολόγοι",
		"Παιδοδοντίατροι",
		"Παιδοενδοκρινολόγοι",
		"Παιδοκαρδιολόγοι",
		"Παιδοορθοπαιδικοί",
		"Παιδοπνευμονολόγοι",
		"Παιδορευματολόγοι",
		"Παιδοχειρουργοί",
		"Παιδοψυχίατροι",
		"Παιδοψυχολόγοι",
		"Παιδο-ΩΡΛ",
		"Περιοδοντολόγοι",
		"Πλαστικοί Χειρουργοί",
		"Πνευμονολόγοι - Φυματιολόγοι",
		"Ποδολόγοι",
		"Προσθετολόγοι",
		"Ρευματολόγοι",
		"Στοματολόγοι",
		"Φυσίατροι",
		"Φυσικοθεραπευτές",
		"Χειρουργοί Ενδοκρινών Αδένων",
		"Χειρουργοί στόματος",
		"Ψυχίατροι",
		"Ωτορινολαρυγγολόγοι (ΩΡΛ)",
	];

	var username = null,
		first_name = null,
		last_name = null,
		email = null,
		role = null,
		postal = afmData.postalZipCode,
		address = afmData.postalAddress,
		numbe = afmData.postalAddressNo,
		country = "Ελλάδα",
		tel1 = null,
		tel2 = null,
		vat = afmData.afm,
		perioxi = afmData.postalAreaDescription,
		doy = afmData.doyDescr,
		nomos = afmData.postalAreaDescription,
		category = null,
		type = null,
		price_tag = null,
		password = null,
		confirm_pass = null,
		epwnimia = afmData.onomasia,
		drastiriotita = afmData.firmActDescr;

	var require = 0,
		required = [];

	async function fetchCord(address1, numbe1, postal1) {
		console.log(address1);
		activeOverlay=true;
		if (
			email != null &&
			password != null &&
			confirm_pass != null &&
			password == confirm_pass &&
			nomos != null &&
			category != null &&
			perioxi != null &&
			address != null &&
			postal != null
		) {
			const resCordinates = await fetch(
				"https://maps.googleapis.com/maps/api/geocode/json?address=" +
					address1 +
					"+" +
					numbe1 +
					"," +
					postal1 +
					"&key=" +
					googleApiKey
			);
			const resjCord = await resCordinates.json();
			if (resjCord.status == "OK") {
				var cordLat = resjCord.results[0].geometry.location.lat;
				var cordLng = resjCord.results[0].geometry.location.lng;
				var cordStatus = resjCord.status;
			}
			if (resjCord.status == "ZERO_RESULTS") {
				cordLat = null;
				cordLng = null;
				cordStatus = "ZERO_RESULTS";
			}
			// console.log(resjCord.status);
			createUser(cordLat, cordLng, cordStatus);
		} else {
			required = [];
			if (email == null) {
				required.push("Email");
			} else {
			}
			if (nomos == null) {
				required.push("Νομός");
			}
			if (category == null) {
				required.push("Παρακαλώ επιλέξτε ειδικότητα");
			}
			if (perioxi == null) {
				required.push("Περιοχή");
			}
			if (password == null) {
				required.push("Κωδικός");
			}
			if (confirm_pass == null) {
				required.push("Επιβαιβέωση κωδικού");
			}
			if (confirm_pass === password) {
				required.push("Δεν ταιριάζουν οι κωδικοί");
			}
			if (address == null) {
				required.push("Διέυθυνση");
			}
			if (postal == null) {
				required.push("Τ.Κ.");
			}
			require = 1;
			activeOverlay=false;
		}
	}

	async function createUser(cordlat, cordlng, cordstatus) {
		const res = await fetch("/api/v1/users", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				username: epwnimia,
				first_name: first_name,
				last_name: last_name,
				email: email,
				role: role,
				postal: postal,
				address: address,
				numbe: numbe,
				country: country,
				tel1: tel1,
				tel2: tel2,
				vat: vat,
				doy: doy,
				perioxi: perioxi,
				category: category[0].value,
				nomos: nomos,
				type: type,
				price_tag: drastiriotita,
				lat: cordlat,
				lng: cordlng,
				cord_status: cordstatus,
				password: password,
				confirm_password: confirm_pass,
			}),
		});
		json = await res.json();
		if(res.ok){			
			console.log(json);
			snackbarMessage='Ο λογαριασμός σας καταχωρήθηκε.'
			snackColor='green';
			activeOverlay=false;
			snackbar = true;
			finish = false;
			// closeModalCreateUser = false			
		}else if (res.status==409){
			activeOverlay=false;
			snackColor='red';
			snackbarMessage='Το e-mail που δηλώσατε υπάρχει ήδη.';
			snackbar = true;
			// console.log(json);
			// console.log(json.msg);
		}else if (res.status==400){
			activeOverlay=false;
			snackColor='orange';
			snackbarMessage=res.errors[0].msg;
			snackbar = true;
		}

		// function playSound(url) {
		// const audio = new Audio(url);
		// audio.play();
		// }

		// console.log(JSON.stringify({
		// 		username: username,
		// 		first_name: first_name,
		// 		last_name: last_name,
		// 		email: email,
		// 		role: role,
		// 		postal: postal,
		// 		address: address,
		// 		numbe: numbe,
		// 		country: country,
		// 		tel1: tel1,
		// 		tel2: tel2,
		// 		vat: vat,
		// 		doy: doy,
		// 		perioxi: perioxi,
		// 		category: category,
		// 		nomos: nomos,
		// 		type: type,
		// 		price_tag: price_tag,
		// 		lat: cordlat,
		// 		lng: cordlng,
		// 		cord_status: cordstatus,
		// 		password: password,
		// 		confirm_password: confirm_pass
		// 	}))
	}

	onDestroy(() => (afmModal = false));
</script>

<!-- <svelte:head>
	<link
		rel="stylesheet"
		href="https://unpkg.com/svelte-material-ui/bare.css"
	/>
</svelte:head> -->
	<!-- {#if json != null} -->
	<MaterialApp>
		<Snackbar class="justify-space-between {snackColor} white-text" bind:active={snackbar} right top  outlined timeout={6000}>
			{snackbarMessage}
			<Button
			  text
			  on:click={() => {
				snackbar = false;
			  }}>
			  OK
			</Button>
		  </Snackbar>
	</MaterialApp>
	<!-- {/if} -->
	<MaterialApp>
	{#if finish}
	<form name="create_user" on:submit|preventDefault={fetchCord(address, numbe, postal)}>
		
		<Overlay
		absolute
		bind:active={activeOverlay} />
		<Row>
			<Col>
				<TextField required outlined bind:value={epwnimia} id='organization'>Επωνυμία</TextField>
			</Col>
		</Row>
		<Row>
			<Col>
				<TextField required outlined bind:value={drastiriotita}
					>Δραστηριότητα</TextField
				>
			</Col>
		</Row>
		<Row>
			<Col cols={12} md={3}>
				<TextField required outlined bind:value={vat}>ΑΦΜ</TextField>
			</Col>
			<Col cols={12} md={3}>
				<TextField required outlined bind:value={doy}>ΔΟΥ</TextField>
			</Col>
			<Col cols={12} md={6}>
				<!-- <TextField solo bind:value={category}>Ειδικότητα</TextField> -->
				<div class="themed">
					<Select items={idikotites} isMulti={true} bind:value={category} placeholder='Ειδικότητα' isCreatable={true}></Select>
				</div>
			</Col>
		</Row>
		<Row>
			<Col cols={12} md={6}>
				<TextField required outlined bind:value={first_name} id='fname'>Όνομα</TextField>
			</Col>
			<Col cols={12} md={6}>
				<TextField required outlined bind:value={last_name} id='lname'>Επώνυμο</TextField>
			</Col>

		</Row>
		<Row>
			<Col cols={12} md={6}>
				<TextField required outlined bind:value={address} id='street-address'>Διέυθυσνη</TextField>
			</Col>
			<Col cols={12} md={3}>
				<TextField required outlined bind:value={numbe}>Αριθμός</TextField>
			</Col>
			<Col cols={12} md={3}>
				<TextField required outlined bind:value={postal} id='postal-code'>T.K.</TextField>
			</Col>
		</Row>
		<Row>
			<Col cols={12} md={4}>
				<TextField required outlined bind:value={perioxi}>Περιοχή-Πόλη</TextField>
			</Col>
			<Col cols={12} md={4}>
				<TextField outlined bind:value={nomos}>Νομός</TextField>				
			</Col>
			<Col cols={12} md={4}>
				<TextField outlined bind:value={country}>Χώρα</TextField>
			</Col>
		</Row>
		<Row>
			<Col cols={12} md={6} sm={6}>
				<TextField required outlined bind:value={email} rules={emailRules} id='email'>Email</TextField>
			</Col>
			<Col cols={12} md={3} sm={6}>
				<TextField required outlined bind:value={tel1} id='tel'>Τηλέφωνο</TextField>
			</Col>
			<Col cols={12} md={3} sm={6}>
				<TextField required outlined bind:value={tel2} id='mobile'>Κινητό</TextField>
			</Col>
		</Row>
		<!-- </MaterialApp> -->
		<!-- <MaterialApp> -->
		<Row>
			<Col>
				<TextField required
					outlined
					type={show ? "text" : "password"}
					bind:value={password}
				>
					Κωδικός
					<div
						slot="append"
						on:click={() => {
							show = !show;
						}}
					>
						<Icon path={show ? mdiEyeOff : mdiEye} />
					</div>
				</TextField>
			</Col>
			<Col>
				<TextField required
					outlined
					type={show ? "text" : "password"}
					bind:value={confirm_pass}
				>
					Επανάληψη Κωδ.
					<div
						slot="append"
						on:click={() => {
							show = !show;
						}}
					>
						<Icon path={show ? mdiEyeOff : mdiEye} />
					</div>
				</TextField>
			</Col>
		</Row>

		<Row>
			{#if require == 1}
				<div class="space-y-4">
					{#each required as must}
						<span class="block text-red-500 ml-5 mt-0"
							>✘ {must}</span
						>
					{/each}
				</div>
			{/if}
		</Row>
		<Row>
			<Col>
				<Button
					block
					class="red white-text"
					on:click={() => (closeModalCreateUser = false)}
				>
					Aκυρο
				</Button>
			</Col>
			<Col>
				<Button type="submit"
					block
					class="blue white-text"
					>Εγγραφη</Button> <!--on:click={fetchCord(address, numbe, postal)}-->
			</Col>
			<!-- <Button block class="blue white-text" on:click={playSound('https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3')}>Save</Button> -->
		</Row>
		
	</form>
	{:else}
	<div class="justify-center text-center"> 
		<div class="text-center">              
	   Ο λογαριασμός σας καταχωρήθηκε με <span class="green-text">επιτυχία</span>! ✅
	   <br/>
	   Έχουμε στείλει έναν σύνδεσμο στο: <span class="green-text">{email}</span> ωστε να γίνει επαλήθευση. ⌛
	  </div>
	   <div class="d-flex justify-center" style="height: 217px;"> 
	   <img src="https://medil.gr/site_images/check-mark-verified.gif" alt="order complete"/>
	  </div>               
	   <div class="text-center"> 
		<Button
		block
		class="blue white-text"
		on:click={() => (closeModalCreateUser = false)}>
			Σας ευχαριστουμε για την εγγραφη!
		</Button>
	   
	  </div>
	  </div>
	{/if}
	</MaterialApp>

	<style>	
		/* 	
				CSS variables can be used to control theming.
				https://github.com/rob-balfre/svelte-select/blob/master/docs/theming_variables.md
		*/
		
		.themed {
			--border: 3px solid blue;
			--borderRadius: 10px;
			--placeholderColor: blue;
			--height: 52px;
		}
	</style>