<script>
    import SelectComp from "svelte-select";
    import SearchProduct from "../products/SearchProduct.svelte";
    import { onMount } from "svelte";
    onMount(async () => {
        categories_fetch();
    });

    let arrayOfTeams =[]
    let changeStateOfSearch=0

    // let Filter = {teamName:'', filters:[{filterName:'', ids:[]}]}

    let selectedValues;
    let c_json = null;
    async function categories_fetch() {
        const c_fetch = await fetch(
            "/api/v1/shop/categories?category_level=products",
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                    Accept: "application/json",
                },
            }
        );
        if (c_fetch.ok) {
            c_json = await c_fetch.json();
            return c_json;
        } else {
            throw new Error(c_fetch.message);
        }
    }

    function changeStateOfSearchFunc(){
        changeStateOfSearch=Math.floor(Math.random() * 1000)
    }

    function deleteProduct(t,f,i){
        changeStateOfSearchFunc()
        arrayOfTeams[t].filters[f].ids.splice(i, 1)
        arrayOfTeams=arrayOfTeams
    }
    function deleteTeam(t){
        arrayOfTeams.splice(t, 1)
        arrayOfTeams=arrayOfTeams
    }
    function deleteFilter(t,f){
        arrayOfTeams[t].filters.splice(f, 1)
        arrayOfTeams=arrayOfTeams
    }
    // $: console.log(selectedValues)
    async function saveToDb(){
        for(let i=0; i<arrayOfTeams.length; i++){
            for(let j=0; j<arrayOfTeams[i].filters.length; j++){
                for(let x=0; x<arrayOfTeams[i].filters[j].ids.length; x++){
                   const {id_product, name_product, ...rest}=arrayOfTeams[i].filters[j].ids[x]
                   arrayOfTeams[i].filters[j].ids[x]={id_product, name_product}
                }
            }
        }
        const res_category_update = await fetch(
            "/api/v1/shop/categories/" + selectedValues.value,
            {
                method: "PATCH",
                credentials: "include",
                headers: {
                    Authorization:
                        "8UKDHJ_medil_by_andre_koukouroukou 56526658741",
                        "Content-Type": "application/json",
                        Accept: "application/json"
                },
                body: JSON.stringify({
                    filters: arrayOfTeams
                }),
            }
        );
    }
</script>

<div class="container mx-auto">
    <div class="my-2 ">Δημιουργία ομάδας φίλτρων</div>

    <div class="flex w-full items-center space-x-3">
        <div class="w-fit">Επιλέξτε κατηγορία</div>
        <div class="w-[80%]">
            <SelectComp
                items={c_json}
                isMulti={false}
                bind:value={selectedValues}
                placeholder="Κατηγορία"
            />
        </div>
        {#if arrayOfTeams.length} 
        <button class="p-1 bg-blue-500 text-white rounded-md" on:click={saveToDb}>
            Αποθήκευση
        </button>
        {/if}
    </div>

    {#each arrayOfTeams as team, t}
        <div class="w-full border-[1px] border-indigo-500 rounded-lg p-2 my-2">
            <div class="flex justify-between items-center">
                <label for="teamName">Όνομα ομάδας</label>
                <button on:click={()=>deleteTeam(t)} class="p-1 rounded-full bg-red-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hover:text-red-600 w-5 h-5" >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                </button>  
            </div>
            <input type="text" id="teamName" class="w-full rounded-md p-1 border-2 border-green-300 bg-gray-100" bind:value={team.teamName}>
            <div class="pl-4">
                {#each team.filters as filter, f}
                <div class="flex justify-between">
                    <label for="filterName">Όνομα φίλτρου</label>
                    <button on:click={()=>deleteFilter(t,f)} class="p-1 rounded-full bg-red-200">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hover:text-red-600 w-5 h-5" >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </button>
                </div>
                <input type="text" id="filterName" class="w-full rounded-md p-1 border-2 border-green-300 bg-gray-100" bind:value={arrayOfTeams[t].filters[f].filterName}>
                <div class="flex my-2">
                    {#key changeStateOfSearch}
                    <SearchProduct bind:list={arrayOfTeams[t].filters[f].ids}/>
                    {/key}
                    <div class="w-full min-h-[20px] h-fit flex flex-wrap">
                        {#each arrayOfTeams[t].filters[f].ids as product, i}
                            <div class="p-1 m-1 h-fit rounded-lg bg-indigo-200 flex items-center">
                                {product.name_product}
                                <button on:click={()=>deleteProduct(t,f,i)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-1 hover:text-red-500 w-5 h-5" >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                                </button>                            
                            </div>
                        {/each}
                    </div>
                </div>
                {/each}
                <button class="w-full h-10 border-dashed border-2 border-green-500 rounded-lg text-center items-center text-green-500 font-semibold my-2" on:click={()=>{arrayOfTeams[t].filters.push({filterName:'', ids:[]}); arrayOfTeams=arrayOfTeams}}>Νέο φίλτρο</button>
            </div>
        </div>
    {/each}
    {#if selectedValues}    
    <button class="w-full h-24 border-dashed border-2 border-indigo-500 rounded-lg text-center items-center text-indigo-500 font-semibold my-2" on:click={()=>{arrayOfTeams.push({teamName:'', filters:[{filterName:'', ids:[]}]}); arrayOfTeams=arrayOfTeams}}>Νέα ομάδα φίλτρων</button>
    {/if}
</div>
